<template>
  <div>
    <div class="fixed top-0 left-0 w-full h-full z-10">
      <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
    </div>
    <div
      v-if="
        !waitingSignature &&
        !waitingLinkCreation &&
        !waitingTransaction &&
        !deleteSuccessful &&
        !displayQrCode &&
        !displayQrCodeVerif &&
        !displayDateSelector &&
        !displayTicketOptionSelector
      "
    >
      <div
        class="fixed w-modal-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-5 py-4 border rounded-xl shadow-md z-10"
      >
        <div v-if="template.data" class="flex items-center">
          <icon :name="`badge-${type.toLowerCase()}`" class="ml-2 my-2 mr-2" />
          <p class="text-lg font-bold my-2">
            {{ templateLabel }}
          </p>
          <div class="grow"></div>
          <div
            @click="triggerCloseDisplay"
            class="text-xl text-gray-400 mr-2 cursor-pointer"
          >
            <icon name="cross" />
          </div>
        </div>
        <div v-if="template.data" class="flex gap-10 p-10">
          <div class="mt-[30px]">
            <TemplatePreview
              v-if="template.data && currentIssuerInfo"
              :templateImage="template.data.image"
              :templateName="template.data.name"
              :templateLocation="
                type == 'Participation' || type == 'Ticket'
                  ? template.data.eventDetails.location.split(', ')[0]
                    ? template.data.eventDetails.location.split(', ')[0]
                    : $t('templateDisplay.virtualTxt')
                  : null
              "
              :templateStartDate="
                type == 'Participation' || type == 'Ticket'
                  ? template.data.eventDetails.startDate
                  : null
              "
              :templateEndDate="
                type == 'Participation' || type == 'Ticket'
                  ? template.data.eventDetails.endDate
                  : null
              "
              :templateHexColor="
                type == 'Role' ? template.data.hexColorRef : null
              "
              :templateSessions="template.sessions"
              :templateContractState="template.contractState"
              :issuerName="currentIssuerInfo.profile.name"
              :formatXl="type != 'Membership' ? true : false"
              :type="type"
            />
          </div>
          <div
            class="grow flex flex-col"
            :class="
              type == 'Membership' && menuSection != 'history'
                ? 'h-[232px]'
                : 'h-[406px]'
            "
          >
            <div
              class="flex border-b border-gray-600 border-opacity-20 w-full mb-5"
            >
              <p
                @click="menuSection = 'information'"
                class="text-sm pr-4 mr-4 cursor-pointer"
                :class="
                  menuSection == 'information'
                    ? 'border-b-2 border-black font-medium pb-2 '
                    : 'text-gray-600'
                "
              >
                {{ $t('templateDisplay.templateSection') }}
              </p>
              <p
                @click="menuSection = 'history'"
                class="text-sm pr-4 cursor-pointer"
                :class="
                  menuSection == 'history'
                    ? 'border-b-2 border-black font-medium pb-2 '
                    : 'text-gray-600'
                "
              >
                {{ $t('templateDisplay.historySection') }}
              </p>
            </div>
            <div v-if="menuSection == 'information'">
              <p
                v-if="
                  [
                    'Basic',
                    'Community',
                    'Participation',
                    'Ticket',
                    'Role',
                  ].includes(type)
                "
                class="text-xl font-bold mb-5"
              >
                {{ template.data.name }}
              </p>
              <p
                v-if="['Membership'].includes(type)"
                class="text-xl font-bold mt-3 mb-5"
              >
                {{ $t('templateDisplay.membershipDefaultTitle') }}
              </p>
              <div v-if="['Role'].includes(type)" class="flex text-sm mb-5">
                <div class="flex-none w-40 text-gray-400">
                  <icon name="color" class="fill-gray-400 mr-1.5" />{{
                    $t('templateDisplay.colorTxt')
                  }}
                </div>
                <div
                  class="w-4 h-4 inline-flex rounded-full cursor-pointer ml-0.5 mt-0.5"
                  :style="`background-color:${template.data.hexColorRef}`"
                ></div>
              </div>
              <div v-if="['Role'].includes(type)" class="flex text-sm mb-5">
                <div class="flex-none w-40 text-gray-400">
                  <icon name="smiley" class="fill-transparent mr-1.5" />{{
                    $t('templateDisplay.emojiTxt')
                  }}
                </div>
                <img :src="template.data.image" class="w-5" />
              </div>
              <div class="flex text-sm mb-5">
                <div class="flex-none w-40 text-gray-400">
                  <icon name="text-align-left" class="fill-gray-400 mr-1.5" />{{
                    $t('templateDisplay.descriptionTxt')
                  }}
                </div>
                <div class="grow">
                  <p class="text-sm line-clamp-1 break-all">
                    {{ template.data.description }}
                  </p>
                </div>
              </div>
              <div
                v-if="
                  [
                    'Basic',
                    'Community',
                    'Participation',
                    'Ticket',
                    'Role',
                  ].includes(type)
                "
                class="flex text-sm mb-5"
              >
                <div class="flex-none w-40 text-gray-400">
                  <icon name="checklist" class="fill-gray-400 mr-1.5" />{{
                    $t('templateDisplay.criteriaTxt')
                  }}
                </div>
                <div class="grow">
                  <p class="text-sm line-clamp-1 break-all">
                    {{ template.data.criteria }}
                  </p>
                </div>
              </div>
              <div
                v-if="['Participation', 'Ticket'].includes(type)"
                class="flex text-sm mb-5"
              >
                <div class="flex-none w-40 text-gray-400">
                  <icon name="calendar" class="fill-gray-400 mr-1.5" />{{
                    $t('templateDisplay.startDateTxt')
                  }}
                </div>
                <div class="grow">
                  {{
                    template.data.eventDetails.startDate
                      .replace('T', ' ')
                      .split('.')[0]
                  }}
                </div>
              </div>
              <div
                v-if="['Participation', 'Ticket'].includes(type)"
                class="flex text-sm mb-5"
              >
                <div class="flex-none w-40 text-gray-400">
                  <icon name="calendar" class="fill-gray-400 mr-1.5" />{{
                    $t('templateDisplay.endDateTxt')
                  }}
                </div>
                <div class="grow">
                  {{
                    template.data.eventDetails.endDate
                      .replace('T', ' ')
                      .split('.')[0]
                  }}
                </div>
              </div>
              <div
                v-if="['Participation', 'Ticket'].includes(type)"
                class="flex text-sm mb-5"
              >
                <div class="flex-none w-40 text-gray-400">
                  <icon name="map-pin" class="fill-gray-400 mr-1.5" />{{
                    $t('templateDisplay.locationTxt')
                  }}
                </div>
                <div class="grow">
                  {{
                    template.data.eventDetails.location
                      ? template.data.eventDetails.location
                      : $t('templateDisplay.virtualTxt')
                  }}
                </div>
              </div>
              <div
                v-if="['Basic', 'Role'].includes(type)"
                class="flex text-sm mb-5"
              >
                <div class="flex-none w-40 text-gray-400">
                  <icon name="calendar-check" class="fill-gray-400 mr-1.5" />{{
                    $t('templateDisplay.validityTxt')
                  }}
                </div>
                <div class="grow">
                  {{ templateValidityFormatted }}
                </div>
              </div>
              <div v-if="['Basic'].includes(type)" class="flex text-sm mb-5">
                <div class="flex-none w-40 text-gray-400">
                  <icon name="clock-check" class="fill-gray-400 mr-1.5" />{{
                    $t('templateDisplay.timestampTxt')
                  }}
                </div>
                <div class="grow">
                  {{
                    template.data.timestamped
                      ? $t('templateDisplay.yesTxt')
                      : $t('templateDisplay.noTxt')
                  }}
                </div>
              </div>
              <div
                v-if="['Participation'].includes(type)"
                class="flex text-sm mb-5"
              >
                <div class="flex-none w-40 text-gray-400">
                  <icon
                    name="layer"
                    class="fill-transparent stroke-gray-400 mr-1.5"
                  />{{ $t('templateDisplay.quantityTxt') }}
                </div>
                <div class="grow">
                  {{
                    template.data.eventDetails.limit == -1
                      ? $t('templateDisplay.infiniteTxt')
                      : template.data.eventDetails.limit
                  }}
                </div>
              </div>
              <div
                v-if="
                  ['Basic', 'Participation', 'Ticket', 'Role'].includes(type) &&
                  template.data.points
                "
                class="flex text-sm mb-5"
              >
                <div class="flex-none w-40 text-gray-400">
                  <icon name="cup" class="fill-gray-400 w-3 mr-3" />{{
                    $t('templateDisplay.pointsTxt')
                  }}
                </div>
                <div class="grow">
                  {{ template.data.points }}
                </div>
              </div>
              <div
                v-if="
                  ['Ticket'].includes(type) &&
                  (template.data.eventDetails.name ||
                    template.data.eventDetails.seat ||
                    template.data.eventDetails.section)
                "
                class="flex text-sm"
              >
                <div class="flex-none w-40 text-gray-400">
                  <icon name="checkbox-fill" class="fill-gray-400 mr-1.5" />{{
                    $t('templateDisplay.optionnalDetailsTxt')
                  }}
                </div>
                <div class="grow flex gap-4">
                  <p
                    v-if="template.data.eventDetails.name"
                    class="text-sm font-medium"
                  >
                    {{ $t('templateDisplay.optionnalDetailsNameTxt') }}
                  </p>
                  <p
                    v-if="template.data.eventDetails.seat"
                    class="text-sm font-medium"
                  >
                    {{ $t('templateDisplay.optionnalDetailsSeatTxt') }}
                  </p>
                  <p
                    v-if="template.data.eventDetails.section"
                    class="text-sm font-medium"
                  >
                    {{ $t('templateDisplay.optionnalDetailsSectionTxt') }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="menuSection == 'information'" class="grow"></div>
            <div
              v-if="
                [
                  'Basic',
                  'Participation',
                  'Ticket',
                  'Membership',
                  'Role',
                ].includes(type)
              "
              class="flex justify-between border border-gray-100 shadow-md rounded-xl p-3 mb-1"
            >
              <div class="flex items-center">
                <div
                  class="border w-10 h-10 flex justify-center items-center rounded-full mr-3"
                >
                  <icon name="paper-plane" class="fill-black w-5" />
                </div>
                <p class="text-xl font-bold mr-2">
                  {{ ongoingSessions + deliveredBadges }}
                </p>
                <p class="text-sm text-gray-600">
                  {{
                    ongoingSessions + deliveredBadges > 1
                      ? $t('other.badgesTxt')
                      : $t('other.badgeTxt')
                  }}
                </p>
              </div>
              <div class="flex items-center">
                <div
                  class="bg-[#8280FF] bg-opacity-30 w-10 h-10 flex justify-center items-center rounded-full mr-3"
                >
                  <icon name="ongoing" class="fill-[#8280FF] w-5" />
                </div>
                <p class="text-xl font-bold mr-2">{{ ongoingSessions }}</p>
                <p class="text-sm text-gray-600">
                  {{ $t('other.pendingTxt') }}
                </p>
              </div>
              <div class="flex items-center">
                <div
                  class="bg-[#8280FF] bg-opacity-30 w-10 h-10 flex justify-center items-center rounded-full mr-3"
                >
                  <icon name="cube" class="fill-[#8280FF] w-5" />
                </div>
                <p class="text-xl font-bold mr-2">
                  {{ deliveredBadges }}
                </p>
                <p class="text-sm text-gray-600">
                  {{
                    deliveredBadges > 1
                      ? $t('other.ownersTxt')
                      : $t('other.ownerTxt')
                  }}
                </p>
              </div>
            </div>
            <div
              v-if="menuSection == 'history'"
              class="grow flex flex-col border border-gray-100 overflow-hidden shadow-md rounded-xl p-3 mt-5 mb-1"
            >
              <div class="w-full flex p-2">
                <p class="w-[15%] text-sm uppercase font-bold">
                  <icon name="unfold-more" class="mr-1" />{{
                    $t('templateDisplay.historyDateLabel')
                  }}
                </p>
                <p class="w-[10%] text-sm uppercase font-bold">
                  <icon name="unfold-more" class="mr-1" />{{
                    $t('templateDisplay.historyLinkLabel')
                  }}
                </p>
                <p class="w-[25%] text-sm uppercase font-bold pl-2">
                  <icon name="unfold-more" class="mr-1" />{{
                    $t('templateDisplay.historyEmailLabel')
                  }}
                </p>
                <p class="w-[20%] text-sm uppercase font-bold">
                  <icon name="unfold-more" class="mr-1" />{{
                    $t('templateDisplay.historyNotesLabel')
                  }}
                </p>
                <p class="w-[20%] text-sm uppercase font-bold pl-3">
                  <icon name="unfold-more" class="mr-1" />{{
                    $t('templateDisplay.historyStateLabel')
                  }}
                </p>
                <p class="w-[10%] pl-3"><icon name="paper-plane"></icon></p>
              </div>
              <div class="grow overflow-y-scroll">
                <div
                  v-for="(session, sessionIndex) in template.sessions
                    .slice()
                    .reverse()
                    .filter((session) => session.didLimit == 1)"
                  :key="session"
                  class="w-full flex p-2"
                >
                  <p class="w-[15%] text-sm pr-2">
                    {{ new Date(session.created).toLocaleDateString() }}
                  </p>
                  <p
                    @click="copyToClipboard(session.dynamicLink)"
                    class="w-[10%] text-sm px-2 cursor-pointer"
                    :title="session.dynamicLink"
                  >
                    <icon name="link" class="pl-3" />
                  </p>
                  <p
                    class="w-[25%] text-sm line-clamp-1 break-all pl-4 pr-2"
                    :title="
                      session.emails && session.emails.length > 0
                        ? session.emails[0]
                        : '-'
                    "
                  >
                    {{
                      session.emails && session.emails.length > 0
                        ? session.emails[0]
                        : '-'
                    }}
                  </p>
                  <p
                    v-if="!sessionsNotes[sessionIndex].edit"
                    @click="
                      () => {
                        sessionsNotes[sessionIndex].edit = true;
                        sessionsNotes[sessionIndex].note = session.note;

                        nextTick(() => {
                          $refs[`inputRef_${sessionIndex}`][0].focus();
                        });
                      }
                    "
                    class="w-[20%] text-sm line-clamp-1 break-all pl-4 pr-2 cursor-pointer"
                    :title="sessionsNotes[sessionIndex].note"
                  >
                    <span v-if="sessionsNotes[sessionIndex].note">{{
                      sessionsNotes[sessionIndex].note
                    }}</span>
                    <span v-else class="text-gray-300">{{
                      $t('templateDisplay.historyNoteAddPlaceholder')
                    }}</span>
                  </p>
                  <div v-else class="w-[20%] relative">
                    <input
                      :ref="`inputRef_${sessionIndex}`"
                      class="w-full py-0 text-sm border-none border-transparent focus:border-transparent focus:ring-0 placeholder:text-gray-300 cursor-pointer rounded-md pl-4 pr-5"
                      :placeholder="
                        $t('templateDisplay.historyNoteAddPlaceholder')
                      "
                      maxlength="100"
                      v-model="sessionsNotes[sessionIndex].note"
                    />
                    <div
                      @click="
                        () => {
                          saveNote(session, sessionsNotes[sessionIndex].note);
                          sessionsNotes[sessionIndex].edit = false;
                        }
                      "
                      class="absolute top-0 right-0 cursor-pointer"
                    >
                      <icon name="save" class="fill-gray-400 w-4" />
                    </div>
                  </div>

                  <div class="w-[20%] pl-6">
                    <p
                      v-if="session.didHistory.length == 1"
                      class="text-center text-xs font-medium text-green-500 bg-green-100 rounded-md py-1"
                    >
                      {{ $t('templateDisplay.historyStateOwnerTxt') }}
                    </p>
                    <p
                      v-else-if="
                        session.didHistory.length == 0 &&
                        new Date(session.delegationBadge.expirationDate) >
                          new Date()
                      "
                      class="text-center text-xs font-medium text-blue-500 bg-blue-100 rounded-md py-1"
                    >
                      {{ $t('templateDisplay.historyStatePendingTxt') }}
                    </p>
                    <p
                      v-else
                      class="text-center text-xs font-medium text-orange-500 bg-orange-100 rounded-md py-1"
                    >
                      {{ $t('templateDisplay.historyStateExpiredTxt') }}
                    </p>
                  </div>

                  <div
                    v-if="
                      session.didHistory.length == 0 &&
                      new Date(session.delegationBadge.expirationDate) <
                        new Date() &&
                      session.emails &&
                      session.emails.length > 0
                    "
                    class="w-[10%] text-center cursor-pointer"
                    @click="generateCustomLink(session.challenge)"
                  >
                    <icon name="paper-plane" class="border p-0.5 rounded-lg" />
                  </div>
                  <div v-else class="w-[10%]"></div>
                </div>
                <div
                  v-if="
                    template.sessions.filter((session) => session.didLimit == 1)
                      .length == 0
                  "
                >
                  <p class="text-center text-sm text-gray-600 mt-5">
                    {{ $t('templateDisplay.historyEmptyTxt') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center mt-4">
          <div class="grow"></div>
          <button
            v-if="template.contractState == 'none'"
            @click="publishTemplate"
            class="text-sm bg-secondary text-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
          >
            <icon name="radio-fill" class="text-xs fill-white mr-1.5" />
            {{ $t('templateDisplay.publishButton') }}
          </button>
          <!-- <button
            v-if="
              template.contractState == 'creating' ||
              template.contractState == 'updating'
            "
            class="text-sm bg-white pointer-events-none font-medium py-1.5 pl-2 pr-2.5 ml-auto mr-3"
            :class="{
              'pointer-events-none ': template.contractState != 'stored',
            }"
          >
            <div class="flex gap-1.5">
              <Vue3Lottie
                :animationLink="require('/assets/images/loader-round.jpg')"
                :height="20"
                :width="20"
                :speed="1"
              />
              {{ $t('templateDisplay.publishingButton') }}
            </div>
          </button> -->
          <button
            v-if="
              [
                'Basic',
                'Role',
                'Participation',
                'Ticket',
                'Membership',
              ].includes(type) && template.contractState != 'none'
            "
            @click="sendBadge"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
          >
            <icon name="paper-plane" class="mr-1.5" />{{
              $t('templateDisplay.sendButton')
            }}
          </button>
          <button
            v-if="
              ['Participation', 'Membership'].includes(type) &&
              template.contractState != 'none'
            "
            @click="triggerQrCode"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
          >
            <icon name="qr-code" class="mr-1.5" />{{
              $t('templateDisplay.getLinkButton')
            }}
          </button>
          <button
            v-if="
              ['Basic', 'Participation', 'Membership', 'Role'].includes(type) &&
              template.contractState != 'none'
            "
            @click="generateCustomLink() && (showMore = false)"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
          >
            <icon name="link" class="mr-1.5" />{{
              $t('templateDisplay.generateLinkButton')
            }}
          </button>
          <button
            v-if="['Ticket'].includes(type) && template.contractState != 'none'"
            @click="
              (!template.data.eventDetails.name &&
              !template.data.eventDetails.seat &&
              !template.data.eventDetails.section
                ? generateCustomLink()
                : (displayTicketOptionSelector = true)) && (showMore = false)
            "
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
            :class="{
              'pointer-events-none opacity-50':
                template.contractState != 'stored',
            }"
          >
            <icon name="link" class="mr-1.5" />{{
              $t('templateDisplay.generateLinkButton')
            }}
          </button>
          <button
            v-if="
              ['Community'].includes(type) || template.contractState == 'none'
            "
            @click="triggerEdit() && (showMore = false)"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
            :class="{
              'pointer-events-none opacity-50':
                template.contractState != 'stored' &&
                template.contractState != 'none',
            }"
          >
            <icon name="edit-pencil" class="mr-1.5" />{{
              $t('templateDisplay.editButton')
            }}
          </button>
          <button
            v-if="template.contractState == 'none'"
            @click="deleteTemplate() && (showMore = false)"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 pl-2 pr-2.5 ml-auto mr-3"
          >
            <icon name="trash" class="mr-1.5" />{{
              $t('templateDisplay.deleteButton')
            }}
          </button>
          <button
            v-if="template.contractState != 'none'"
            @click="showMore = !showMore"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2 ml-auto mr-3"
            click-outside-ignore-moremenu
          >
            <icon name="more-vertical" />
          </button>
          <div v-if="showMore" class="relative">
            <div
              class="absolute w-[15rem] bottom-5 -left-[15.8rem] bg-white px-3 py-2 border rounded-xl shadow-md"
              v-click-outside="
                () => {
                  showMore = false;
                }
              "
              click-outside-ignore-moremenu-target
            >
              <p
                v-if="['Ticket'].includes(type)"
                @click="generateVerification() && (showMore = false)"
                class="text-sm font-medium cursor-pointer mb-1.5"
                :class="{
                  'pointer-events-none opacity-50':
                    template.contractState != 'stored',
                }"
              >
                <icon name="user-square" class="mr-1.5" />{{
                  $t('templateDisplay.generateVerifierQrcode')
                }}
              </p>
              <p
                v-if="
                  [
                    'Basic',
                    'Participation',
                    'Ticket',
                    'Membership',
                    'Role',
                  ].includes(type)
                "
                @click="triggerEdit() && (showMore = false)"
                class="text-sm font-medium cursor-pointer"
                :class="{
                  'pointer-events-none opacity-50':
                    template.contractState != 'stored' &&
                    template.contractState != 'none',
                }"
              >
                <icon name="edit-pencil" class="mr-1.5" />{{
                  $t('templateDisplay.editButton')
                }}
              </p>
              <p
                v-if="['Basic'].includes(type) && moreToolAuthorized"
                @click="generateOpenLink() && (showMore = false)"
                class="text-sm font-medium cursor-pointer mt-2"
                :class="{
                  'pointer-events-none opacity-50':
                    template.contractState != 'stored',
                }"
              >
                <icon name="link" class="mr-1.5" />{{
                  $t('templateDisplay.generateCustomLinkButton')
                }}
              </p>
              <p
                v-if="['Basic'].includes(type) && moreToolAuthorized"
                @click="(displayDateSelector = true) && (showMore = false)"
                class="text-sm font-medium cursor-pointer mt-2"
                :class="{
                  'pointer-events-none opacity-50':
                    template.contractState != 'stored',
                }"
              >
                <icon name="link" class="mr-1.5" />{{
                  $t('templateDisplay.generateCustomLinkLimitedButton')
                }}
              </p>
              <p
                v-if="
                  ['Basic'].includes(type) &&
                  moreToolAuthorized &&
                  sessionBuilders.length == 0
                "
                @click="generateSessionBuilder() && (showMore = false)"
                class="text-sm font-medium cursor-pointer mt-2"
                :class="{
                  'pointer-events-none opacity-50':
                    template.contractState != 'stored',
                }"
              >
                <icon name="key" class="w-4 mr-2" />{{
                  $t('templateDisplay.generateApiKey')
                }}
              </p>
              <p
                v-if="
                  ['Basic'].includes(type) &&
                  moreToolAuthorized &&
                  sessionBuilders.length > 0
                "
                @click="showSessionBuilderApiKey() && (showMore = false)"
                class="text-sm font-medium cursor-pointer mt-2"
                :class="{
                  'pointer-events-none opacity-50':
                    template.contractState != 'stored',
                }"
              >
                <icon name="key" class="w-4 mr-2" />{{
                  $t('templateDisplay.copyApiKey')
                }}
              </p>
              <div
                v-if="
                  ['Basic', 'Participation', 'Ticket', 'Role'].includes(type)
                "
                class="border-b my-2"
              ></div>
              <p
                @click="deleteTemplate() && (showMore = false)"
                v-if="
                  [
                    'Basic',
                    'Community',
                    'Participation',
                    'Ticket',
                    'Role',
                  ].includes(type)
                "
                class="text-sm text-red-500 font-medium cursor-pointer"
                :class="{
                  'pointer-events-none opacity-50':
                    template.contractState != 'stored' &&
                    template.contractState != 'none',
                }"
              >
                <icon name="trash" class="fill-red-500 mr-1.5" />{{
                  $t('templateDisplay.deleteButton')
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="waitingSignature">
      <SignatureHandler
        @ack="waitingSignatureAck = false"
        @close="waitingSignature = false"
        ref="signatureHandlerRef"
      >
        <div v-if="!waitingSignatureAck" class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-app.jpg')"
              :height="160"
              :width="160"
              :speed="0.7"
            />
          </div>
          <p
            v-if="waitingSignature == 'signDelete'"
            class="text-center text-xl font-bold px-20 mb-1"
          >
            {{
              $t('templateDisplay.confirmDeleteTxt') +
              ' ' +
              templateLabel.toLowerCase()
            }}
          </p>
          <p
            v-if="waitingSignature == 'signDelegation'"
            class="text-center text-xl font-bold px-20 mb-1"
          >
            {{ $t('templateDisplay.confirmDelegationCreationTxt') }}
          </p>
          <p class="text-center px-10 mb-4">
            {{ $t('templateDisplay.onMydidAppTxt') }}
          </p>
        </div>
        <div v-else class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-wait-app.jpg')"
              :height="100"
              :width="100"
              :speed="1"
            />
          </div>
          <p class="text-center text-xl font-bold px-20 mb-4">
            {{ $t('other.waitingAppConnection') }}
          </p>
        </div></SignatureHandler
      >
    </div>
    <div v-else-if="waitingTransaction">
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-xl font-bold px-10">
          {{ $t('templateDisplay.deleteInProgess') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="waitingLinkCreation">
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-xl font-bold px-10">
          {{ $t('templateDisplay.linkInProgess') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-show="deleteSuccessful">
      <BasicSmModal @close="triggerCloseDisplay">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-check.jpg')"
            :height="160"
            :width="160"
            :speed="0.5"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ $t('templateDisplay.successDeleteTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-show="displayQrCode">
      <BasicSmModal @close="displayQrCode = false">
        <p class="text-center text-lg font-bold mb-4 p-5">
          {{ $t('templateDisplay.qrCodeTxt') }}
        </p>
        <div v-if="qrCodeLink" class="bg-white flex justify-center mb-10">
          <qrcode-vue :value="qrCodeLink" :size="200" level="H" :margin="1" />
        </div>
        <div class="flex w-3/4 border items-center rounded-md mb-3 mx-auto">
          <input
            type="text"
            rows="1"
            v-model="qrCodeLink"
            class="grow border-none rounded-xl"
            readonly="readonly"
          />
          <p @click="copyToClipboard(qrCodeLink)" class="mx-2 cursor-pointer">
            <icon name="copy" />
          </p>
        </div>
        <div class="flex justify-center">
          <button
            @click="downloadQRCode"
            class="shadow-sm font-medium border rounded-lg cursor-pointer py-1.5 px-2.5"
          >
            <icon name="download" class="mr-1.5" />{{
              $t('templateDisplay.downloadButton')
            }}
          </button>
        </div>
      </BasicSmModal>
    </div>
    <div v-show="displayQrCodeVerif">
      <BasicSmModal @close="displayQrCodeVerif = false">
        <p class="text-center text-lg font-bold mb-4 p-5">
          {{ $t('templateDisplay.qrCodeVerifTxt') }}
        </p>
        <div v-if="qrCodeVerifValue" class="bg-white flex justify-center mb-10">
          <qrcode-vue
            :value="qrCodeVerifValue"
            :size="200"
            level="L"
            :margin="1"
          />
        </div>
      </BasicSmModal>
    </div>
    <div v-show="displayDateSelector">
      <BasicSmModal @close="displayDateSelector = false" class="text-center">
        <p class="mb-3">Sélectionner une période ci-dessous :</p>
        <datepicker
          class="border-none"
          :inline="true"
          :enable-time-picker="false"
          :start-time="[
            { hours: 0, minutes: 0 },
            { hours: 23, minutes: 59 },
          ]"
          range
          auto-apply
          v-model="dateRange"
          @update:model-value="
            () => {
              templateStartDate = startDatePickerValue;
              showStartDatePicker = false;
            }
          "
        />
        <div v-if="dateRange && dateRange.length == 2" class="mt-3">
          <p class="text-sm mb-3">
            {{
              new Date(dateRange[0]).toLocaleDateString() +
              ' - ' +
              new Date(dateRange[1]).toLocaleDateString()
            }}
          </p>
          <button
            @click="generateLimitedOpenLink"
            class="text-sm bg-white border shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2"
          >
            Sélectionner
          </button>
        </div>
      </BasicSmModal>
    </div>
    <div v-if="displayTicketOptionSelector">
      <BasicSmModal
        @close="displayTicketOptionSelector = false"
        :large="true"
        :absoluteClose="true"
      >
        <p class="font-medium mb-3">
          <icon name="link" class="mr-1.5" />{{
            $t('templateDisplay.generateLinkButton')
          }}
        </p>
        <p class="text-gray-400">
          {{ $t('templateDisplay.ticketOptionsTxt') }}
        </p>
        <p class="text-gray-400 mb-7">
          {{ $t('templateDisplay.ticketOptionsTxtBis') }}
        </p>
        <div class="flex my-10">
          <div class="w-[60%] px-16">
            <div v-if="template.data.eventDetails.name" class="w-full mb-3">
              <div class="uppercase font-medium pb-1">
                {{ $t('templateDisplay.ticketOptionNameTxt') }}
              </div>
              <textarea
                oninput='this.style.height = "";this.style.height = (this.scrollHeight > 96 ? 96 : this.scrollHeight) + "px"'
                class="w-full max-h-[28rem] overflow-y-auto resize-none border border-gray-200 focus:ring-0 placeholder:text-gray-300 hover:bg-gray-light cursor-pointer rounded-md py-1.5 px-2 -mb-1.5"
                :placeholder="
                  $t('templateDisplay.ticketoptionsNamePlaceholder')
                "
                v-model="ticketOptionName"
                rows="1"
                maxlength="2000"
              />
            </div>
            <div v-if="template.data.eventDetails.seat" class="w-full mb-3">
              <div class="uppercase font-medium pb-1">
                {{ $t('templateDisplay.ticketOptionSeatTxt') }}
              </div>
              <textarea
                oninput='this.style.height = "";this.style.height = (this.scrollHeight > 96 ? 96 : this.scrollHeight) + "px"'
                class="w-full max-h-[28rem] overflow-y-auto resize-none border border-gray-200 focus:ring-0 placeholder:text-gray-300 hover:bg-gray-light cursor-pointer rounded-md py-1.5 px-3 -mb-1.5"
                :placeholder="
                  $t('templateDisplay.ticketoptionsSeatPlaceholder')
                "
                v-model="ticketOptionSeat"
                rows="1"
                maxlength="2000"
              />
            </div>
            <div v-if="template.data.eventDetails.section" class="w-full mb-3">
              <div class="uppercase font-medium pb-1">
                {{ $t('templateDisplay.ticketOptionSectionTxt') }}
              </div>
              <textarea
                oninput='this.style.height = "";this.style.height = (this.scrollHeight > 96 ? 96 : this.scrollHeight) + "px"'
                class="w-full max-h-[28rem] overflow-y-auto resize-none border border-gray-200 focus:ring-0 placeholder:text-gray-300 hover:bg-gray-light cursor-pointer rounded-md py-1.5 px-3 -mb-1.5"
                :placeholder="
                  $t('templateDisplay.ticketoptionsSectionPlaceholder')
                "
                v-model="ticketOptionSection"
                rows="1"
                maxlength="2000"
              />
            </div>
          </div>
          <div class="w-[40%] flex items-center px-16">
            <img
              :src="require('/assets/images/ticket.png')"
              alt=""
              class="w-full"
            />
          </div>
        </div>
        <div class="flex justify-end">
          <button
            @click="generateTicketCustomLink"
            class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer mt-7 py-1.5 px-2"
          >
            {{ $t('templateDisplay.generateButton') }}
          </button>
        </div>
      </BasicSmModal>
    </div>
  </div>
</template>
<style scoped>
.dp__main::v-deep .dp__action_select {
  background-color: rgb(79 70 229) !important;
}
.dp__main::v-deep .dp__active_date {
  background-color: rgb(79 70 229) !important;
}
.dp__main::v-deep .dp__outer_menu_wrap {
  margin: auto !important;
}
</style>
<script>
import { ref, inject, computed, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { createToast } from 'mosha-vue-toastify';
import { useI18n } from 'vue-i18n';
import TemplatePreview from '../../components/templates/TemplatePreview.vue';
import TemplateRolePreview from '../../components/templates/TemplateRolePreview.vue';
import TemplateLabel from '../../components/templates/TemplateLabel.vue';
import SignatureHandler from '../../components/SignatureHandler.vue';
import BasicSmModal from '../../components/BasicSmModal.vue';
export default {
  props: {
    template: Object,
    index: Number,
    type: String,
  },
  setup(props, context) {
    const store = useStore();
    const utils = inject('utils');
    const api = inject('api');
    const { t } = useI18n();

    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const currentAddress = computed(() => store.getters.getCurrentAddress);

    const templateLabel = ref(utils.templateTypeToLabel(props.type, useI18n()));
    const signatureHandlerRef = ref();
    const waitingSignature = ref(null);
    const waitingSignatureAck = ref(false);
    const waitingLinkCreation = ref(false);
    const waitingTransaction = ref(false);
    const deleteSuccessful = ref(false);
    const displayQrCode = ref(false);
    const displayQrCodeVerif = ref(false);
    const qrCodeVerifValue = ref('');
    const displayDateSelector = ref(false);
    const displayTicketOptionSelector = ref(false);
    const dateRange = ref();
    const ticketOptionName = ref(null);
    const ticketOptionSeat = ref(null);
    const ticketOptionSection = ref(null);
    const showMore = ref(false);
    const menuSection = ref('information');
    const sessionsNotes = ref(
      Array.from(
        {
          length: props.template.sessions
            .slice()
            .reverse()
            .filter((session) => session.didLimit == 1).length,
        },
        (_, index) => {
          return {
            note: props.template.sessions
              .slice()
              .reverse()
              .filter((session) => session.didLimit == 1)[index].note
              ? props.template.sessions
                  .slice()
                  .reverse()
                  .filter((session) => session.didLimit == 1)[index].note
              : '',
            edit: false,
          };
        }
      )
    );
    watch(props.template, () => {
      sessionsNotes.value = Array.from(
        {
          length: props.template.sessions
            .slice()
            .reverse()
            .filter((session) => session.didLimit == 1).length,
        },
        (_, index) => {
          return {
            note: props.template.sessions
              .slice()
              .reverse()
              .filter((session) => session.didLimit == 1)[index].note
              ? props.template.sessions
                  .slice()
                  .reverse()
                  .filter((session) => session.didLimit == 1)[index].note
              : '',
            edit: false,
          };
        }
      );
    });

    const sessionBuilders = ref(null);
    const templateCategory = ref(
      ['Basic', 'Community', 'Participation', 'Membership', 'Role'].indexOf(
        props.type
      )
    );
    if (props.type == 'Ticket') templateCategory.value = 2;

    const ongoingSessions = computed(() => {
      if (!props.template.sessions) return null;

      return props.template.sessions.filter((session) => {
        if (session.didLimit != 1) return false;
        return session.didHistory.length == 0;
      }).length;
    });
    const deliveredBadges = computed(() => {
      if (!props.template.sessions) return null;

      return props.template.sessions.reduce((sum, session) => {
        return sum + session.didHistory.length;
      }, 0);
    });
    const qrCodeLink = ref(null);

    if (props.template.sessions) {
      const openSessionList = props.template.sessions.filter(
        (session) => session.active && session.didLimit != 1
      );
      if (openSessionList.length > 0)
        qrCodeLink.value =
          openSessionList[openSessionList.length - 1].dynamicLink;
    }

    const moreToolAuthorized = computed(() => {
      return (
        process.env.VUE_APP_MORE_TOOLS_AUTHORIZED_ISSUER_DIDS &&
        process.env.VUE_APP_MORE_TOOLS_AUTHORIZED_ISSUER_DIDS.includes(
          currentIssuerDid.value
        )
      );
    });

    if (
      props.template.contractState == 'creating' ||
      props.template.contractState == 'updating'
    ) {
      const interval = setInterval(async () => {
        const operation = (await api.getOperation(props.template.callbackId))
          .data;
        if (operation.state == 'success') {
          console.log('operation successful !');
          clearInterval(interval);
          context.emit('refresh');
        } else if (operation.state == 'error') {
          console.log('operation failed !');
          clearInterval(interval);
          context.emit('refresh');
        }
      }, 1000);
    }

    watch(waitingSignature, () => {
      if (waitingSignature.value) waitingSignatureAck.value = true;
    });

    const templateActiveDate = computed(() => {
      return utils.formatSimpleDate(props.template.data.date);
    });
    const templateDate = computed(() => {
      return utils.formatSimpleDate(props.template.data.date);
    });

    const templateValidityFormatted = computed(() => {
      if (props.template.data.expirationDate) {
        return (
          t('templateDisplay.expiresOnTxt') +
          ' ' +
          utils.formatSimpleDate(props.template.data.expirationDate)
        );
      } else if (props.template.data.expirationDuration) {
        return (
          t('templateDisplay.validDurationTxt') +
          ' ' +
          Math.round(
            props.template.data.expirationDuration / (60 * 60 * 24 * 30)
          ) +
          ' ' +
          (Math.round(
            props.template.data.expirationDuration / (60 * 60 * 24 * 30)
          ) > 1
            ? t('templateDisplay.monthsTxt')
            : t('templateDisplay.monthTxt'))
        );
      } else return t('templateDisplay.infiniteTxt');
    });

    function getBadgeBotSessionBuilders() {
      api.getSessionBuilders(props.template.templateHash).then((res) => {
        sessionBuilders.value = res.data;
      });
    }
    getBadgeBotSessionBuilders();

    async function showSessionBuilderApiKey() {
      await navigator.clipboard.writeText(sessionBuilders.value[0].apiKey);
      createToast(t('other.clipboardCopyTxt'), {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#050931',
      });
    }

    async function copyToClipboard(value) {
      await navigator.clipboard.writeText(value);
      createToast(t('other.clipboardCopyTxt'), {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#050931',
      });
    }

    async function downloadQRCode() {
      const canvas = document.querySelector('canvas');
      const image = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');

      let downloadLink = document.createElement('a');
      downloadLink.href = image;
      downloadLink.download = 'QRCode.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

    function triggerQrCode() {
      if (qrCodeLink.value) displayQrCode.value = true;
      else generateOpenLink();
    }

    async function publishTemplate() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Publish',
          'Confirm',
        ]);

      waitingSignature.value = 'signDelegation';

      const name = props.type == 'Membership' ? '' : props.template.data.name;

      const methodName = 'addIssuerTemplate';
      const methodArgs = [
        {
          type: 'bytes32[]',
          value: ['0x' + props.template.templateHash],
        },
        {
          type: 'bytes32[]',
          value: [
            '0x' +
              (
                '0000000000000000000000000000000000000000000000000000000000000000' +
                utils.asciiToHex(name).replace('0x', '')
              ).substr(-64),
          ],
        },
        {
          type: 'uint8',
          value: templateCategory.value,
        },
        {
          type: 'address',
          value: currentAddress.value,
        },
      ];

      const message = await utils.broadcast.broadcastEncode(
        currentAddress.value,
        methodName,
        ...methodArgs
      );

      let broadcastSignature = null;
      let badgeWithoutProof;
      let delegationSignature = null;

      if (
        props.type == 'Participation' ||
        props.type == 'Membership' ||
        props.type == 'Ticket'
      ) {
        let delegationExpirationDate =
          new Date(new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 19) + 'Z';
        if (props.type == 'Participation' || props.type == 'Ticket') {
          delegationExpirationDate =
            new Date(props.template.data.eventDetails.endDate)
              .toISOString()
              .slice(0, 19) + 'Z';
        }

        badgeWithoutProof = utils.badge.createDelegationBadge(
          uuidv4(),
          currentIssuerDid.value + '#SERV_1',
          process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
          props.template.data.name.replace(/\0/g, ''),
          props.template.templateHash,
          delegationExpirationDate
        );
        const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

        let signatures = null;
        try {
          signatures = await signatureHandlerRef.value.sign(
            'MultiSign',
            'signAdd' + props.type,
            [
              {
                type: 'personal_sign',
                message,
              },
              {
                type: 'eth_signTypedData',
                message: typedData,
              },
            ]
          );
        } catch (e) {
          console.log(e);
          waitingSignature.value = null;
          return;
        }

        [broadcastSignature, delegationSignature] = signatures;
      } else {
        let signatures = null;
        try {
          signatures = await signatureHandlerRef.value.sign(
            'MultiSign',
            'signAdd' + props.type,

            [
              {
                type: 'personal_sign',
                message,
              },
            ]
          );
        } catch (e) {
          console.log(e);
          waitingSignature.value = null;
          return;
        }

        [broadcastSignature] = signatures;
      }

      // Verify signature
      const messageHash = utils.hashWithKeccak(message);
      const recoveredAddress = utils.sign.recoverPersonalSign(
        broadcastSignature,
        '0x' + messageHash
      );
      if (recoveredAddress != currentAddress.value) {
        throw new Error('Signature invalid for broadcast');
      }

      api
        .queueOperation('createTemplate', {
          did: currentIssuerDid.value,
          address: currentAddress.value,
          methodName,
          methodArgs,
          signature: broadcastSignature,
        })
        .then(() => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.type}`,
              'Publish',
              'Success',
            ]);

          waitingSignature.value = null;
          context.emit('refresh');
        });

      // verify delegation badge and create qr code at badge bot
      if (
        props.type == 'Participation' ||
        props.type == 'Membership' ||
        props.type == 'Ticket'
      ) {
        const delegationBadge = await utils.sign.addProofToVerifiableCredential(
          badgeWithoutProof,
          currentIssuerDid.value,
          delegationSignature
        );

        // Verifying signature
        const badgeRecoveredAddress =
          await utils.sign.recoverVCTypedSignatureV4(delegationBadge);

        if (badgeRecoveredAddress != currentAddress.value) {
          throw new Error('Signature invalid for verifiable credential');
        }

        // send to badge bot
        api
          .createSession({
            verifiableCredentials: [],
            delegationBadge,
            ...(props.template.data.eventDetails && {
              startDate: props.template.data.eventDetails.startDate,
            }),
            ...(props.template.data.eventDetails &&
              props.template.data.eventDetails.limit != -1 && {
                didLimit: props.template.data.eventDetails.limit,
              }),
          })
          .then((res) => {
            qrCodeLink.value = res.data.links[0];
          });
      }
    }

    async function deleteTemplate() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Delete',
          'Confirm',
        ]);

      if (props.template.contractState == 'none') {
        api.deleteTemplate(props.template.templateHash).then(() => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.type}`,
              'Delete draft',
              'Success',
            ]);
          context.emit('refresh');
          context.emit('close');
        });

        return;
      }

      waitingSignature.value = 'signDelete';

      const methodName = 'updateIssuerTemplate';
      const methodArgs = [
        {
          type: 'bytes32',
          value:
            '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF',
        },
        {
          type: 'bytes32',
          value:
            '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF',
        },
        {
          type: 'uint256',
          value: Number(props.template.index),
        },
        {
          type: 'uint8',
          value: templateCategory.value,
        },
        {
          type: 'address',
          value: currentAddress.value,
        },
      ];

      const message = await utils.broadcast.broadcastEncode(
        currentAddress.value,
        methodName,
        ...methodArgs
      );

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelete' + props.type,
          [
            {
              type: 'personal_sign',
              message,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      // Verify signature
      const messageHash = utils.hashWithKeccak(message);
      const recoveredAddress = utils.sign.recoverPersonalSign(
        signature,
        '0x' + messageHash
      );
      if (recoveredAddress != currentAddress.value) {
        throw new Error('Signature invalid for broadcast');
      }

      Promise.all([
        api.queueOperation('deleteTemplate', {
          did: currentIssuerDid.value,
          address: currentAddress.value,
          methodName,
          methodArgs,
          signature,
        }),
        api.deleteTemplate(props.template.templateHash),
      ])
        .then((res) => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.type}`,
              'Delete',
              'Success',
            ]);
          waitingSignature.value = null;
          context.emit('refresh');
          context.emit('close');
        })
        .catch((error) => {
          waitingSignature.value = null;
          console.error('Error while deleting template :', error);
          context.emit('refresh');
          context.emit('close');
        });
    }

    async function generateSessionBuilder() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Create API key',
          'Confirm',
        ]);
      const delegationExpirationDate =
        new Date(new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 19) + 'Z';

      const badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        props.template.data.name.replace(/\0/g, ''),
        props.template.templateHash,
        delegationExpirationDate
      );

      waitingSignature.value = 'signDelegation';

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelegation' + props.type,
          [
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      waitingSignature.value = null;
      waitingLinkCreation.value = true;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        signature
      );

      // Verifying signature
      const badgeRecoveredAddress = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );
      if (badgeRecoveredAddress != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      // send to badge bot
      api
        .createSessionBuilder({
          delegationBadge,
        })
        .then((res) => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.type}`,
              'Create API key',
              'Success',
            ]);
          console.log(res.data);
          waitingLinkCreation.value = false;
          getBadgeBotSessionBuilders();
        });
    }

    async function generateOpenLink() {
      let delegationExpirationDate =
        new Date(new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 19) + 'Z';
      if (props.type == 'Participation' || props.type == 'Ticket') {
        delegationExpirationDate =
          new Date(props.template.data.eventDetails.endDate)
            .toISOString()
            .slice(0, 19) + 'Z';
      }

      const badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        props.template.data.name.replace(/\0/g, ''),
        props.template.templateHash,
        delegationExpirationDate
      );

      waitingSignature.value = 'signDelegation';

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelegation' + props.type,
          [
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      waitingSignature.value = null;
      waitingLinkCreation.value = true;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        signature
      );

      // Verifying signature
      const badgeRecoveredAddress = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );
      if (badgeRecoveredAddress != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      // send to badge bot
      api
        .createSession({
          verifiableCredentials: [],
          delegationBadge,
          ...(props.template.data.eventDetails && {
            startDate: props.template.data.eventDetails.startDate,
          }),
          ...(props.template.data.eventDetails &&
            props.template.data.eventDetails.limit != -1 && {
              didLimit: props.template.data.eventDetails.limit,
            }),
        })
        .then((res) => {
          qrCodeLink.value = res.data.links[0];
          waitingLinkCreation.value = false;
          displayQrCode.value = true;
          store.dispatch('refreshTemplateSessions');
        });
    }

    async function generateTicketCustomLink() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Create ticket custom link',
          'Confirm',
        ]);
      displayTicketOptionSelector.value = false;
      // expiration date is +30 days from now
      let delegationExpirationDate =
        new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 19) + 'Z';
      if (
        new Date(delegationExpirationDate) <
        new Date(props.template.data.eventDetails.endDate)
      ) {
        delegationExpirationDate =
          new Date(props.template.data.eventDetails.endDate)
            .toISOString()
            .slice(0, 19) + 'Z';
      }

      const badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        props.template.data.name.replace(/\0/g, ''),
        props.template.templateHash,
        delegationExpirationDate
      );

      waitingSignature.value = 'signDelegation';

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelegation' + props.type,
          [
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      waitingSignature.value = null;
      waitingLinkCreation.value = true;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        signature
      );

      // Verifying signature
      const badgeRecoveredAddress = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );
      if (badgeRecoveredAddress != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      // send to badge bot

      api
        .createSession({
          verifiableCredentials: [],
          delegationBadge,
          didLimit: 1,
          ...(props.template.data.eventDetails && {
            startDate: props.template.data.eventDetails.startDate,
          }),
          options: [
            {
              ...(props.template.data.eventDetails.name && {
                name: ticketOptionName.value,
              }),
              ...(props.template.data.eventDetails.seat && {
                seat: ticketOptionSeat.value,
              }),
              ...(props.template.data.eventDetails.section && {
                section: ticketOptionSection.value,
              }),
            },
          ],
        })
        .then((res) => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.type}`,
              'Create ticket custom link',
              'Success',
            ]);
          qrCodeLink.value = res.data.links[0];
          waitingLinkCreation.value = false;
          displayQrCode.value = true;
          store.dispatch('refreshTemplateSessions');
        });
    }

    async function generateLimitedOpenLink() {
      displayDateSelector.value = false;
      const delegationExpirationDate =
        new Date(dateRange.value[1]).toISOString().slice(0, 19) + 'Z';

      const badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        props.template.data.name.replace(/\0/g, ''),
        props.template.templateHash,
        delegationExpirationDate
      );

      waitingSignature.value = 'signDelegation';

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelegation' + props.type,
          [
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      waitingSignature.value = null;
      waitingLinkCreation.value = true;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        signature
      );

      // Verifying signature
      const badgeRecoveredAddress = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );
      if (badgeRecoveredAddress != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      // send to badge bot
      api
        .createSession({
          verifiableCredentials: [],
          delegationBadge,
          startDate: dateRange.value[0],
        })
        .then((res) => {
          qrCodeLink.value = res.data.links[0];
          waitingLinkCreation.value = false;
          displayQrCode.value = true;
          store.dispatch('refreshTemplateSessions');
        });
    }

    async function generateCustomLink(sessionChallenge) {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Create custom link',
          'Confirm',
        ]);

      // expiration date is +30 days from now
      let delegationExpirationDate =
        new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 19) + 'Z';
      if (
        (props.type == 'Participation' || props.type == 'Ticket') &&
        new Date(delegationExpirationDate) <
          new Date(props.template.data.eventDetails.endDate)
      ) {
        delegationExpirationDate =
          new Date(props.template.data.eventDetails.endDate)
            .toISOString()
            .slice(0, 19) + 'Z';
      }

      const badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        props.template.data.name.replace(/\0/g, ''),
        props.template.templateHash,
        delegationExpirationDate
      );

      waitingSignature.value = 'signDelegation';

      // Sign verifiable credential
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      let signature = null;
      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signDelegation' + props.type,
          [
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }
      [signature] = signatures;

      waitingSignature.value = null;
      waitingLinkCreation.value = true;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        signature
      );

      // Verifying signature
      const badgeRecoveredAddress = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );
      if (badgeRecoveredAddress != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      // send to badge bot
      if (!sessionChallenge)
        api
          .createSession({
            verifiableCredentials: [],
            delegationBadge,
            didLimit: 1,
            ...(props.template.data.eventDetails && {
              startDate: props.template.data.eventDetails.startDate,
            }),
          })
          .then((res) => {
            if (window._paq)
              window._paq.push([
                'trackEvent',
                `Template ${props.type}`,
                'Create custom link',
                'Success',
              ]);
            qrCodeLink.value = res.data.links[0];
            waitingLinkCreation.value = false;
            displayQrCode.value = true;
            store.dispatch('refreshTemplateSessions');
          });
      else
        api
          .retriggerExpiredSession({
            challenge: sessionChallenge,
            delegationBadge,
          })
          .then((res) => {
            if (window._paq)
              window._paq.push([
                'trackEvent',
                `Template ${props.type}`,
                'Create custom link',
                'Success',
              ]);
            waitingLinkCreation.value = false;
            store.dispatch('refreshTemplateSessions');
            createToast(`Email sent with new invitation`, {
              position: 'bottom-center',
              hideProgressBar: true,
              timeout: 3000,
              transition: 'slide',
              toastBackgroundColor: '#050931',
            });
          });
    }

    async function saveNote(session, note) {
      api
        .updateSessionNote({
          challenge: session.challenge,
          note: note ? note : '',
        })
        .then((res) => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.type}`,
              'Save note',
              'Success',
            ]);
          store.dispatch('refreshTemplateSessions');
        });
    }

    function showToastMessage(text) {
      createToast(text, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    }

    function sendBadge() {
      context.emit('send');
    }

    function triggerCloseDisplay() {
      context.emit('close');
    }

    function triggerDeleteEvent() {
      context.emit('delete');
    }

    function triggerEdit() {
      context.emit('edit');
    }

    function generateVerification() {
      displayQrCodeVerif.value = true;
      showMore.value = false;
      const data = {
        type: 'ValidatorData',
        created: new Date().toISOString(),
        templateHash: props.template.templateHash,
        delegationIssuerDid: props.template.data.issuerDID,
        delegationMessageDataEip712Schema:
          'https://myntfsid.mypinata.cloud/ipfs/QmZgMKBF3Fp9rokyo7qsEbN6stRWZc4R11oiTT7dX9BxAd',
        messageDataEip712Schema:
          props.template.data.badgeCategory == 'Role'
            ? 'https://myntfsid.mypinata.cloud/ipfs/QmR7g3nVV1e1HsJkPP56fQZTguq9L4Q6EXX4gWVpPDX1o3'
            : 'https://myntfsid.mypinata.cloud/ipfs/Qmb1TiAsamCv8eZ7tZNUYz96Vqyj2u7bLTC1uynatd2xxu',
        issuerDid: process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
      };
      qrCodeVerifValue.value = JSON.stringify(data);
    }

    return {
      currentIssuerInfo,
      triggerCloseDisplay,
      sendBadge,
      signatureHandlerRef,
      waitingSignature,
      waitingLinkCreation,
      waitingTransaction,
      waitingSignatureAck,
      deleteSuccessful,
      generateCustomLink,
      generateOpenLink,
      generateLimitedOpenLink,
      generateTicketCustomLink,
      generateSessionBuilder,
      displayQrCode,
      displayQrCodeVerif,
      generateVerification,
      qrCodeLink,
      qrCodeVerifValue,
      showMore,
      deleteTemplate,
      triggerDeleteEvent,
      triggerQrCode,
      triggerEdit,
      templateActiveDate,
      templateDate,
      sessionBuilders,
      templateValidityFormatted,
      templateLabel,
      copyToClipboard,
      downloadQRCode,
      moreToolAuthorized,
      showSessionBuilderApiKey,
      displayDateSelector,
      displayTicketOptionSelector,
      dateRange,
      ticketOptionName,
      ticketOptionSeat,
      ticketOptionSection,
      publishTemplate,
      ongoingSessions,
      deliveredBadges,
      menuSection,
      sessionsNotes,
      nextTick,
      saveNote,
    };
  },
  components: {
    TemplatePreview,
    TemplateRolePreview,
    SignatureHandler,
    BasicSmModal,
    TemplateLabel,
  },
};
</script>

<template>
  <div>
    <TourGuide ref="tourGuideRef" @finish="finishTour()"></TourGuide>
    <div class="fixed top-0 left-0 w-full h-full">
      <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
    </div>
    <div
      v-if="
        !waitingIpfs &&
        !waitingSignature &&
        !waitingTransaction &&
        !creationSuccessful &&
        !updateSuccessful &&
        !showImageHandler &&
        !sendingSuccessful &&
        !sendingInProgress
      "
    >
      <div
        class="fixed w-modal-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-5 py-4 border rounded-xl shadow-md"
      >
        <div class="flex items-center">
          <p class="text-lg text-gray-500 ml-2 mr-1.5 my-2">
            {{
              templateData
                ? $t('templateCreation.editTitle')
                : $t('templateCreation.createTitle')
            }}
          </p>
          <div class="grow"></div>
          <div
            v-if="!noCloseOption"
            @click="triggerCloseEvent"
            class="text-xl text-gray-400 mr-2 cursor-pointer"
          >
            <icon name="cross" class="text-sm fill-gray-300" />
          </div>
        </div>
        <div v-if="currentIssuerInfo" class="flex gap-10 p-10">
          <div
            class="relative"
            @mouseenter="
              templateImagePreview ? (showEditPictureButton = true) : null
            "
            @mouseleave="showEditPictureButton = false"
          >
            <TemplatePreview
              id="tg-createTemplate-preview"
              v-if="currentIssuerInfo"
              :templateImage="
                type != 'Role'
                  ? templateImagePreview
                  : templateEmoji
                  ? templateEmoji.path
                  : null
              "
              :templateName="
                templateName
                  ? templateName
                  : $t('templateCreation.defaultTemplateTitle')
              "
              :templateLocation="
                type == 'Participation' || type == 'Ticket'
                  ? templateLocationVirtual
                    ? $t('templateDisplay.virtualTxt')
                    : templateLocation && templateLocation.name
                    ? templateLocation.name
                    : null
                  : null
              "
              :templateStartDate="
                type == 'Participation' || type == 'Ticket'
                  ? templateStartDateFormatted
                  : null
              "
              :templateEndDate="
                type == 'Participation' || type == 'Ticket'
                  ? templateEndDateFormatted
                  : null
              "
              :templateHexColor="type == 'Role' ? templateColor : null"
              :issuerName="currentIssuerInfo.profile.name"
              :formatXl="type == 'Membership'"
              :type="type"
            />
            <div
              v-if="!templateImagePreview && type != 'Role'"
              class="absolute top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2 pb-10 z-10"
            >
              <button
                id="tg-createTemplate-image"
                class="bg-white border shadow-sm w-max font-medium rounded-lg cursor-pointer py-1.5 px-2 mt-[150px]"
                :class="{
                  '!mt-[380px]': type == 'Ticket',
                  '!mt-40': type == 'Membership',
                }"
                @click="showImageHandler = true"
              >
                <icon name="image" class="mr-1.5" />{{
                  $t('templateCreation.addPictureButton')
                }}
              </button>
            </div>
            <div
              id="tg-createTemplate-image"
              v-else-if="showEditPictureButton && type != 'Role'"
              class="absolute top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2 pb-10"
            >
              <button
                class="bg-white border shadow-sm w-max font-medium rounded-lg cursor-pointer py-1.5 px-2 mt-[150px]"
                :class="{
                  '!mt-[380px]': type == 'Ticket',
                  '!mt-40': type == 'Membership',
                }"
                @click="showImageHandler = true"
              >
                <icon name="image" class="mr-1.5" />{{
                  $t('templateCreation.editPictureButton')
                }}
              </button>
            </div>
          </div>
          <div class="grow">
            <input
              id="tg-createTemplate-title"
              v-if="
                [
                  'Basic',
                  'Community',
                  'Participation',
                  'Ticket',
                  'Role',
                ].includes(type)
              "
              class="w-full !text-xl border-none font-bold border-transparent focus:border-transparent focus:ring-0 placeholder:text-gray-300 hover:bg-gray-light cursor-pointer rounded-md mt-3 mb-5 px-1"
              :placeholder="$t('templateCreation.titlePlaceholder')"
              maxlength="100"
              v-model="templateName"
            />
            <p
              v-if="['Membership'].includes(type)"
              class="text-xl font-bold mt-3 mb-5"
            >
              {{ $t('templateCreation.membershipDefaultTitle') }}
            </p>
            <div id="tg-createTemplate-description" class="flex mb-5">
              <div class="flex-none w-40 text-gray-400">
                <icon name="text-align-left" class="fill-gray-400 mr-1.5" />{{
                  $t('templateCreation.descriptionTxt')
                }}
              </div>
              <div class="grow">
                <textarea
                  oninput='this.style.height = "";this.style.height = (this.scrollHeight > 96 ? 96 : this.scrollHeight) + "px"'
                  class="w-full max-h-[28rem] overflow-y-auto border-none resize-none border-transparent focus:border-transparent focus:ring-0 placeholder:text-gray-300 hover:bg-gray-light cursor-pointer rounded-md py-0 px-1 -mb-1.5"
                  :placeholder="$t('templateCreation.descriptionPlaceholder')"
                  v-model="templateDescription"
                  rows="1"
                  maxlength="2000"
                />
              </div>
            </div>
            <div
              id="tg-createTemplate-criteria"
              v-if="
                [
                  'Basic',
                  'Community',
                  'Participation',
                  'Ticket',
                  'Role',
                ].includes(type)
              "
              class="flex mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="checklist" class="fill-gray-400 mr-1.5" />{{
                  $t('templateCreation.criteriaTxt')
                }}
              </div>
              <div class="grow">
                <textarea
                  oninput='this.style.height = "";this.style.height = (this.scrollHeight > 96 ? 96 : this.scrollHeight) + "px"'
                  class="w-full max-h-[28rem] overflow-y-auto border-none resize-none border-transparent focus:border-transparent focus:ring-0 placeholder:text-gray-300 hover:bg-gray-light cursor-pointer rounded-md py-0 px-1 -mb-1.5"
                  :placeholder="$t('templateCreation.criteriaPlaceholder')"
                  v-model="templateCriteria"
                  rows="1"
                  maxlength="2000"
                />
              </div>
            </div>
            <div
              id="tg-createTemplate-validity"
              v-if="['Basic', 'Role'].includes(type)"
              class="flex mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="calendar-check" class="fill-gray-400 mr-1.5" />{{
                  $t('templateCreation.validityTxt')
                }}
              </div>
              <div class="grow relative">
                <p
                  @click="showValidityMenu = !showValidityMenu"
                  class="hover:bg-gray-light cursor-pointer rounded-md px-1"
                  :class="{ 'text-gray-300': !templateValidity }"
                  click-outside-ignore-validitypicker
                >
                  {{
                    templateValidity
                      ? templateValidityFormatted
                      : $t('templateCreation.validityPlaceholder')
                  }}
                </p>
                <div
                  v-if="showValidityMenu"
                  class="absolute w-modal-xs bg-white border shadow-md rounded-md z-10"
                  v-click-outside="
                    () => {
                      showValidityMenu = false;
                    }
                  "
                  click-outside-ignore-validitypicker-target
                >
                  <p v-if="templateValidity" class="font-medium text-sm p-2">
                    {{ templateValidityFormatted }}
                  </p>
                  <hr v-if="templateValidity" class="border-b" />
                  <p
                    @click="
                      () => {
                        templateValidityChoice = 'infinite';
                        templateValidity = 'infinite';
                        showValidityMenu = false;
                        showValidityMenuDate = false;
                        showValidityMenuDuration = false;
                      }
                    "
                    class="font-medium text-sm hover:bg-gray-light cursor-pointer p-2"
                  >
                    <icon
                      name="infinity"
                      class="fill-transparent mr-1 pt-0.5"
                    />{{ $t('templateCreation.infiniteTxt') }}
                  </p>
                  <p
                    @click="
                      () => {
                        showValidityMenuDuration = false;
                        showValidityMenuDate = !showValidityMenuDate;
                      }
                    "
                    class="font-medium text-sm hover:bg-gray-light cursor-pointer p-2"
                  >
                    <icon name="calendar-event" class="mr-1 pt-0.5" />{{
                      $t('templateCreation.expireTxt')
                    }}
                  </p>
                  <div v-if="showValidityMenuDate" class="h-fit">
                    <datepicker
                      class="bg-gray-200 border-none"
                      :inline="true"
                      :enable-time-picker="false"
                      menu-class-name="w-modal-xs"
                      :preview-format="utils.formatSimpleDate"
                      v-model="datePickerValue"
                      @update:model-value="
                        () => {
                          templateValidity = datePickerValue;
                          templateValidityChoice = 'expirationDate';
                          showValidityMenu = false;
                        }
                      "
                    />
                  </div>
                  <p
                    @click="
                      () => {
                        showValidityMenuDate = false;
                        showValidityMenuDuration = !showValidityMenuDuration;
                      }
                    "
                    class="font-medium text-sm hover:bg-gray-light cursor-pointer p-2"
                  >
                    <icon name="clock" class="mr-1 pt-0.5" />{{
                      $t('templateCreation.durationTxt')
                    }}
                  </p>
                  <div
                    v-if="showValidityMenuDuration"
                    class="grid grid-cols-4 gap-2 p-3 pr-5"
                  >
                    <div
                      v-for="duration of durationMonthValue"
                      :key="duration"
                      @click="
                        () => {
                          templateValidity = duration;
                          templateValidityChoice = 'expirationDuration';
                          showValidityMenu = false;
                        }
                      "
                      class="text-sm text-center rounded-lg cursor-pointer p-2"
                      :class="
                        duration == templateValidity
                          ? 'bg-secondary text-white'
                          : 'hover:bg-gray-light'
                      "
                    >
                      {{
                        duration +
                        ' ' +
                        (duration > 1
                          ? $t('templateCreation.monthsTxt')
                          : $t('templateCreation.monthTxt'))
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="tg-createTemplate-timestamp"
              v-if="['Basic'].includes(type)"
              class="flex"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="clock-check" class="fill-gray-400 mr-1.5" />{{
                  $t('templateCreation.timestampTxt')
                }}
              </div>
              <div class="grow px-1">
                <ToggleButton v-model="templateTimestamped"></ToggleButton>
              </div>
            </div>
            <div
              id="tg-createTemplate-color"
              v-if="['Role'].includes(type)"
              class="flex mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="color" class="fill-gray-400 mr-1.5" />{{
                  $t('templateCreation.colorTxt')
                }}
              </div>
              <div class="grow">
                <div
                  @click="showColorPicker = !showColorPicker"
                  click-outside-ignore-colorpicker
                  class="w-4 h-4 inline-flex rounded-full cursor-pointer ml-1.5 mt-0.5"
                  :style="`background-color:${templateColor}`"
                ></div>
                <div v-if="showColorPicker" class="relative">
                  <div
                    class="absolute top-1 z-10 flex"
                    v-click-outside="
                      () => {
                        showColorPicker = false;
                      }
                    "
                    click-outside-ignore-colorpicker-target
                  >
                    <ColorPicker
                      theme="light"
                      color="#654645"
                      :sucker-hide="true"
                      @changeColor="
                        (color) => {
                          templateColor = color.hex;
                        }
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              id="tg-createTemplate-emoji"
              v-if="['Role'].includes(type)"
              class="flex mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="smiley" class="fill-transparent mr-1.5" />{{
                  $t('templateCreation.emojiTxt')
                }}
              </div>
              <div class="grow">
                <img
                  v-if="templateEmoji"
                  @click="showEmojiPicker = !showEmojiPicker"
                  :src="templateEmoji.path"
                  click-outside-ignore-emojipicker
                  class="cursor-pointer w-5 m-1"
                />
                <div v-if="showEmojiPicker" class="relative">
                  <div
                    click-outside-ignore-emojipicker-target
                    class="absolute w-modal-xs bg-white border shadow-md rounded-md top-1 z-10"
                    v-click-outside="
                      () => {
                        showEmojiPicker = false;
                      }
                    "
                  >
                    <div class="grid grid-cols-10 gap-4 pt-3 px-3">
                      <icon
                        @click="emojiStockIndex = 0"
                        name="emoji_smileys_people"
                        class="text-xl fill-gray-600 cursor-pointer m-1"
                      />
                      <icon
                        @click="emojiStockIndex = 1"
                        name="emoji_animals_nature"
                        class="text-xl fill-gray-600 cursor-pointer m-1"
                      />
                      <icon
                        @click="emojiStockIndex = 2"
                        name="emoji_food_drink"
                        class="text-xl fill-gray-600 cursor-pointer m-1"
                      />
                      <icon
                        @click="emojiStockIndex = 3"
                        name="emoji_activities"
                        class="text-xl fill-gray-600 cursor-pointer m-1 p-0.5"
                      />
                      <icon
                        @click="emojiStockIndex = 4"
                        name="emoji_travel_places"
                        class="text-xl fill-gray-600 cursor-pointer m-1"
                      />
                      <icon
                        @click="emojiStockIndex = 5"
                        name="emoji_objects"
                        class="text-xl fill-gray-600 cursor-pointer m-1"
                      />
                      <icon
                        @click="emojiStockIndex = 6"
                        name="emoji_symbols"
                        class="text-xl fill-gray-600 cursor-pointer m-1"
                      />
                      <icon
                        @click="emojiStockIndex = 7"
                        name="emoji_flags"
                        class="text-xl fill-gray-600 cursor-pointer m-1"
                      />
                    </div>
                    <div class="border-b mx-3 my-2"></div>
                    <div
                      class="grid grid-cols-8 h-52 overflow-y-scroll overflow-x-hidden px-3 pb-3"
                    >
                      <div
                        v-for="emoji in emojiStock[emojiStockIndex]"
                        class="w-6 m-1"
                      >
                        <img
                          @click="templateEmoji = emoji"
                          :src="emoji.path"
                          class="w-full cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="tg-createTemplate-startDate"
              v-if="['Participation', 'Ticket'].includes(type)"
              class="flex mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="calendar" class="fill-gray-400 mr-1.5" />{{
                  $t('templateCreation.startDateTxt')
                }}
              </div>
              <div class="grow relative">
                <p
                  @click="showStartDatePicker = !showStartDatePicker"
                  class="w-full border-none outline outline-0 hover:bg-gray-light cursor-pointer rounded-md px-1"
                  :class="{ 'text-gray-300': !templateStartDateFormatted }"
                  click-outside-ignore-startdatepicker
                >
                  {{
                    templateStartDateFormatted
                      ? templateStartDateFormatted.replace(
                          /T(\d{2}:\d{2}).*/,
                          ' $1'
                        )
                      : $t('templateCreation.startDatePlaceholder')
                  }}
                </p>
                <div
                  v-if="showStartDatePicker"
                  class="absolute w-modal-xs bg-white border shadow-md rounded-md z-10"
                >
                  <div
                    class="h-fit"
                    click-outside-ignore-startdatepicker-target
                    v-click-outside="
                      () => {
                        showStartDatePicker = false;
                      }
                    "
                  >
                    <datepicker
                      class="bg-gray-200 border-none"
                      :inline="true"
                      menu-class-name="w-modal-xs"
                      v-model="startDatePickerValue"
                      :preview-format="formatDateWithLocation"
                      @update:model-value="
                        () => {
                          templateStartDate = startDatePickerValue;
                          showStartDatePicker = false;
                        }
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              id="tg-createTemplate-endDate"
              v-if="['Participation', 'Ticket'].includes(type)"
              class="flex mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="calendar" class="fill-gray-400 mr-1.5" />{{
                  $t('templateCreation.endDateTxt')
                }}
              </div>
              <div class="grow relative">
                <p
                  @click="showEndDatePicker = !showEndDatePicker"
                  class="w-full border-none outline outline-0 hover:bg-gray-light cursor-pointer rounded-md px-1"
                  :class="{ 'text-gray-300': !templateEndDateFormatted }"
                  click-outside-ignore-enddatepicker
                >
                  {{
                    templateEndDateFormatted
                      ? templateEndDateFormatted.replace(
                          /T(\d{2}:\d{2}).*/,
                          ' $1'
                        )
                      : $t('templateCreation.endDatePlaceholder')
                  }}
                </p>
                <div
                  v-if="showEndDatePicker"
                  class="absolute w-modal-xs bg-white border shadow-md rounded-md z-10"
                >
                  <div
                    class="h-fit"
                    click-outside-ignore-enddatepicker-target
                    v-click-outside="
                      () => {
                        showEndDatePicker = false;
                      }
                    "
                  >
                    <datepicker
                      class="bg-gray-200 border-none"
                      :inline="true"
                      menu-class-name="w-modal-xs"
                      v-model="endDatePickerValue"
                      :preview-format="formatDateWithLocation"
                      @update:model-value="
                        () => {
                          templateEndDate = endDatePickerValue;
                          showEndDatePicker = false;
                        }
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              id="tg-createTemplate-virtual"
              v-if="['Participation', 'Ticket'].includes(type)"
              class="flex mb-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="location" class="fill-transparent mr-1.5" />{{
                  $t('templateCreation.virtualTxt')
                }}
              </div>
              <div class="grow px-1">
                <ToggleButton v-model="templateLocationVirtual"></ToggleButton>
              </div>
            </div>
            <div
              id="tg-createTemplate-location"
              v-if="
                ['Participation', 'Ticket'].includes(type) &&
                !templateLocationVirtual
              "
              class="flex mb-5"
            >
              <div
                v-if="citiesAutoComplete && citiesAutoComplete.length > 0"
                class="relative"
              >
                <div
                  v-if="!templateLocation"
                  class="absolute w-[25rem] top-7 left-[18.8rem] bg-white px-3 py-2 border rounded-xl shadow-md max-h-80 overflow-y-auto z-20"
                >
                  <p
                    v-for="city in citiesAutoComplete"
                    :key="city"
                    @click="
                      () => {
                        locationQuery = city.name;
                        templateLocation = city;
                      }
                    "
                    class="text-sm font-medium my-1 hover:bg-gray-light rounded-md cursor-pointer"
                  >
                    {{ city.name }} ({{ city.state_name }})
                  </p>
                </div>
              </div>
              <div class="flex-none w-40 text-gray-400">
                <icon name="location" class="fill-transparent mr-1.5" />{{
                  $t('templateCreation.locationTxt')
                }}
              </div>
              <div v-if="showCountryPicker" class="relative">
                <div
                  class="absolute w-[25rem] top-7 left-0 bg-white px-3 py-2 border rounded-xl shadow-md max-h-40 overflow-y-auto z-20"
                  click-outside-ignore-countrypicker-target
                  v-click-outside="
                    () => {
                      showCountryPicker = false;
                    }
                  "
                >
                  <p
                    v-for="country in countries"
                    :key="country"
                    @click="
                      () => {
                        locationCountry = country;
                        templateLocation = null;
                        locationQuery = '';
                        showCountryPicker = false;
                      }
                    "
                    class="text-sm font-medium my-1 hover:bg-gray-light rounded-md cursor-pointer"
                  >
                    {{ country.name }}
                  </p>
                </div>
              </div>
              <div class="flex-none w-36 relative">
                <div
                  @click="
                    () => {
                      showCountryPicker = !showCountryPicker;
                    }
                  "
                  class="absolute left-0 -top-0.5 cursor-pointer"
                  click-outside-ignore-countrypicker
                >
                  <div
                    class="flex items-center border shadow-md rounded-lg gap-1 px-2 py-1"
                  >
                    <div v-if="locationCountry" class="w-8 h-5 relative">
                      <country-flag
                        :country="locationCountry.iso2"
                        class="absolute bottom-0 left-0 pt-0.5"
                      />
                    </div>
                    <div
                      v-else
                      class="w-8 h-5 bg-gray-light flex items-center justify-center rounded-md"
                    >
                      <icon name="flag" class="fill-gray-400 text-xs m-1.5" />
                    </div>
                    <p class="text-sm text-gray-400 font-medium ml-1">
                      {{ $t('templateCreation.countryTxt') }}
                    </p>
                    <icon name="chevron-down" class="fill-gray-400" />
                  </div>
                </div>
              </div>
              <div class="grow flex overflow-hidden">
                <input
                  v-if="!templateLocation"
                  class="w-full max-h-[28rem] border-transparent focus:border-transparent focus:ring-0 placeholder:text-gray-300 hover:bg-gray-light cursor-pointer rounded-md py-0 px-1 -mb-1.5"
                  :placeholder="$t('templateCreation.locationPlaceholder')"
                  :disabled="!locationCountry"
                  v-model="locationQuery"
                />
                <div v-else class="w-full flex gap-2">
                  <p>
                    {{ templateLocation.name }}
                  </p>
                  <div
                    @click="
                      () => {
                        templateLocation = null;
                        locationQuery = '';
                      }
                    "
                    class="cursor-pointer"
                  >
                    <p>
                      <icon name="cross" class="text-xs fill-gray-500 mb-0.5" />
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="templateLocation" class="hover:bg-gray-light"></div>
            </div>
            <div
              id="tg-createTemplate-quantity"
              v-if="['Participation'].includes(type)"
              class="flex"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon
                  name="layer"
                  class="fill-transparent stroke-gray-400 mr-1.5"
                />{{ $t('templateCreation.quantityTxt') }}
              </div>
              <div class="grow">
                <input
                  class="w-full border-none border-transparent focus:border-transparent focus:ring-0 placeholder:text-gray-300 hover:bg-gray-light cursor-pointer rounded-md py-0 px-1"
                  :placeholder="$t('templateCreation.quantityPlaceholder')"
                  v-model="templateQuantity"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </div>
            </div>
            <div
              v-if="
                ['Basic', 'Participation', 'Ticket', 'Role'].includes(type) &&
                pointAuthorized
              "
              class="flex mt-4"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="cup" class="fill-gray-400 w-3 mr-3" />{{
                  $t('templateCreation.pointsTxt')
                }}
              </div>
              <div class="grow">
                <input
                  class="w-full border-none border-transparent focus:border-transparent focus:ring-0 placeholder:text-gray-300 hover:bg-gray-light cursor-pointer rounded-md py-0 px-1"
                  :placeholder="$t('templateCreation.pointsPlaceholder')"
                  v-model="templatePoints"
                  oninput="this.value = this.value.replace(/[^-+0-9.]/g, '').replace(/(?!^[-+])[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </div>
            </div>
            <div
              id="tg-createTemplate-ticketOptions"
              v-if="['Ticket'].includes(type)"
              class="flex"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="checkbox-fill" class="fill-gray-400 mr-1.5" />{{
                  $t('templateCreation.optionnalDetailsTxt')
                }}
              </div>
              <div class="grow flex gap-5 px-1">
                <div class="flex gap-2">
                  <p class="text-gray-400">
                    {{ $t('templateCreation.optionnalDetailsNameTxt') }}
                  </p>
                  <div class="relative w-10">
                    <ToggleButton
                      class="absolute mt-0.5"
                      v-model="templateOptionName"
                    ></ToggleButton>
                  </div>
                </div>
                <div class="flex gap-2">
                  <p class="text-gray-400">
                    {{ $t('templateCreation.optionnalDetailsSeatTxt') }}
                  </p>
                  <div class="relative w-10">
                    <ToggleButton
                      class="absolute mt-0.5"
                      v-model="templateOptionSeat"
                    ></ToggleButton>
                  </div>
                </div>
                <div class="flex gap-2">
                  <p class="text-gray-400">
                    {{ $t('templateCreation.optionnalDetailsSectionTxt') }}
                  </p>
                  <div class="relative w-10">
                    <ToggleButton
                      class="absolute mt-0.5"
                      v-model="templateOptionSection"
                    ></ToggleButton>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="tg-createTemplate-public"
              v-if="!['Membership'].includes(type) && !templateData"
              class="flex mt-5"
            >
              <div class="flex-none w-40 text-gray-400">
                <icon name="radio-fill" class="fill-gray-400 mr-1.5" />{{
                  $t('templateCreation.publicTxt')
                }}
              </div>
              <div class="grow px-1">
                <ToggleButton
                  class="mt-10"
                  v-model="templatePublic"
                ></ToggleButton>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <p @click="startTour" class="cursor-pointer">
            <icon name="question-circle" class="fill-gray-500 w-6 h-6" />
          </p>
          <div class="grow"></div>
          <button
            id="tg-createTemplate-create"
            @click="templateData ? updateIssuerTemplate() : addIssuerTemplate()"
            class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2.5 ml-auto mr-3"
          >
            {{
              templateData
                ? $t('templateCreation.saveButton')
                : $t('templateCreation.createButton')
            }}
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="waitingIpfs">
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-xl font-bold px-10">
          {{ templateLabel + ' ' + $t('templateCreation.uploadInProgressTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="waitingSignature">
      <SignatureHandler
        @ack="waitingSignatureAck = false"
        @close="waitingSignature = false"
        ref="signatureHandlerRef"
      >
        <div v-if="!waitingSignatureAck" class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-app.jpg')"
              :height="160"
              :width="160"
              :speed="0.7"
            />
          </div>
          <p
            v-if="waitingSignature == 'signatureAdd'"
            class="text-center text-xl font-bold px-20 mb-1"
          >
            {{
              $t('templateCreation.confirmCreateTxt') +
              ' ' +
              templateLabel.toLowerCase()
            }}
          </p>
          <p
            v-else-if="waitingSignature == 'signatureEdit'"
            class="text-center text-xl font-bold px-20 mb-1"
          >
            {{
              $t('templateCreation.confirmUpdateTxt') +
              ' ' +
              templateLabel.toLowerCase()
            }}
          </p>
          <p class="text-center px-10 mb-4">
            {{ $t('templateCreation.onMydidAppTxt') }}
          </p>
        </div>
        <div v-else class="mt-5 mb-10">
          <div class="justify-center mb-5">
            <Vue3Lottie
              :animationLink="require('/assets/images/loader-wait-app.jpg')"
              :height="100"
              :width="100"
              :speed="1"
            />
          </div>
          <p class="text-center text-xl font-bold px-20 mb-4">
            {{ $t('other.waitingAppConnection') }}
          </p>
        </div></SignatureHandler
      >
    </div>
    <div v-else-if="waitingTransaction">
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p
          v-if="waitingTransaction == 'transactionAdd'"
          class="text-center text-xl font-bold px-10"
        >
          {{
            templateLabel + ' ' + $t('templateCreation.createdInProgressTxt')
          }}
        </p>
        <p
          v-else-if="waitingTransaction == 'transactionEdit'"
          class="text-center text-xl font-bold px-10"
        >
          {{ templateLabel + ' ' + $t('templateCreation.updateInProgressTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="creationSuccessful">
      <BasicSmModal
        v-if="type == 'Membership' && !creationSuccessfulSkip"
        @close="triggerCloseEvent"
        :large="true"
      >
        <p class="text-center text-2xl font-bold mb-5">
          {{ $t('templateCreation.cardModal.titleSection1') }}
        </p>
        <div
          v-if="creationSuccessfulCompleted"
          class="bg-white flex justify-center mb-5"
        >
          <qrcode-vue :value="qrCodeLink" :size="120" level="H" :margin="1" />
        </div>
        <div v-else class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center font-medium px-20 mb-3">
          {{ $t('templateCreation.cardModal.copyTxt') }}
        </p>
        <div
          class="flex w-1/2 border items-center rounded-md mb-2 mx-auto"
          :class="{
            'pointer-events-none opacity-50': !creationSuccessfulCompleted,
          }"
        >
          <input
            type="text"
            rows="1"
            v-model="qrCodeLink"
            class="grow border-none text-gray-600 rounded-l-md pr-0"
            readonly="readonly"
          />
          <div
            class="bg-secondary h-10 items-center flex rounded-r-md cursor-pointer"
            @click="copyToClipboard"
          >
            <p class="text-white text-sm mx-3">
              {{ $t('templateCreation.cardModal.copyButton') }}
            </p>
          </div>
        </div>
        <div
          class="w-1/2 mx-auto mb-7"
          :class="{
            'pointer-events-none opacity-50': !creationSuccessfulCompleted,
          }"
        >
          <div
            class="border overflow-y-scroll no-scrollbar rounded-md py-2 px-2 mb-2"
            ref="emailListScrollDiv"
          >
            <div
              v-if="emailList.length > 0"
              class="flex flex-wrap gap-2 mb-1.5"
            >
              <div
                v-for="(email, index) in emailList"
                :key="index"
                class="bg-violet-50 pl-1.5 pr-1 rounded-md"
              >
                <p class="text-gray-500">
                  {{ email
                  }}<span
                    @click="removeFromEmailList(index)"
                    class="cursor-pointer"
                    ><icon
                      name="cross"
                      class="fill-gray-400 text-xxs ml-2 pb-1"
                  /></span>
                </p>
              </div>
            </div>
            <textarea
              class="w-full border-none border-transparent focus:border-transparent focus:ring-0 resize-none p-0"
              :placeholder="
                emailList.length == 0
                  ? $t('templateCreation.cardModal.emailPlaceholder')
                  : ''
              "
              rows="1"
              v-model="emailInput"
              @keydown.enter.prevent="treatEmailInput"
              @focusout="treatEmailInput"
            />
          </div>
        </div>
        <div
          class="w-1/2 mx-auto"
          :class="{
            'pointer-events-none opacity-50': !creationSuccessfulCompleted,
          }"
        >
          <button
            @click="sendOpenLink()"
            class="w-full bg-secondary text-white shadow-sm rounded-md cursor-pointer py-2 px-2.5 mr-3 mb-2"
          >
            {{ $t('templateCreation.cardModal.sendButton') }}
          </button>
        </div>
        <div class="w-1/2 mx-auto">
          <button
            @click="creationSuccessfulSkip = true"
            class="w-full bg-transparent text-gray-400 border shadow-sm rounded-md cursor-pointer py-2 px-2.5 mr-3 mb-2"
          >
            {{ $t('templateCreation.cardModal.skipNowButton') }}
          </button>
        </div>
      </BasicSmModal>
      <BasicSmModal
        v-else-if="type == 'Membership' && creationSuccessfulSkip"
        @close="triggerCloseEvent"
        :large="true"
        ><div class="w-1/2 mx-auto">
          <p class="text-2xl font-bold mb-5">
            {{ $t('templateCreation.cardModal.titleSection2') }}
          </p>
          <p class="font-medium mb-12">
            {{ $t('templateCreation.cardModal.recommandationTxt') }}
          </p>
        </div>
        <div class="w-1/2 mx-auto">
          <button
            @click="creationSuccessfulSkip = false"
            class="w-full bg-secondary text-white shadow-sm rounded-md cursor-pointer py-2 px-2.5 mr-3 mb-2"
          >
            {{ $t('templateCreation.cardModal.cancelButton') }}
          </button>
        </div>
        <div class="w-1/2 mx-auto">
          <button
            @click="triggerCloseEvent"
            class="w-full bg-transparent text-gray-400 border shadow-sm rounded-md cursor-pointer py-2 px-2.5 mr-3 mb-2"
          >
            {{ $t('templateCreation.cardModal.skipButton') }}
          </button>
        </div>
      </BasicSmModal>
      <BasicSmModal v-else @close="triggerCloseEvent">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-check.jpg')"
            :height="160"
            :width="160"
            :speed="0.5"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ templateLabel + ' ' + $t('templateCreation.createdSuccessTxt') }}
        </p>
        <div class="flex justify-center">
          <button
            @click="triggerCloseEvent"
            class="text-sm bg-secondary text-white shadow-sm rounded-md cursor-pointer py-2 px-7"
          >
            {{ $t('templateCreation.showTemplateButton') }}
          </button>
        </div>
      </BasicSmModal>
    </div>
    <div v-else-if="updateSuccessful">
      <BasicSmModal @close="triggerCloseEvent">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-check.jpg')"
            :height="160"
            :width="160"
            :speed="0.5"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ templateLabel + ' ' + $t('templateCreation.updatedSuccessTxt') }}
        </p>
        <div class="flex justify-center">
          <button
            @click="triggerCloseEvent"
            class="text-sm bg-secondary text-white shadow-sm rounded-md cursor-pointer py-2 px-7"
          >
            {{ $t('templateCreation.showTemplateButton') }}
          </button>
        </div>
      </BasicSmModal>
    </div>
    <ImageHandler
      v-if="showImageHandler"
      :type="type"
      @select="
        (file, data) => {
          templateImagePreview = data;
          templateImageFile = file;
          showImageHandler = false;
          showEditPictureButton = false;
        }
      "
      @close="showImageHandler = false"
    ></ImageHandler>
    <div v-else-if="sendingInProgress">
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-gray-400 px-10">
          {{ templateLabel + ' ' + $t('templateSend.sendProgressTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="sendingSuccessful">
      <BasicSmModal @close="triggerCloseEvent">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-send.jpg')"
            :height="160"
            :width="160"
            :speed="1"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ templateLabel + ' ' + $t('templateSend.successSendTxt') }}
        </p>
      </BasicSmModal>
    </div>
  </div>
</template>
<style scoped>
.dp__main::v-deep .dp__action_select {
  background-color: rgb(79 70 229) !important;
}
.dp__main::v-deep .dp__active_date {
  background-color: rgb(79 70 229) !important;
}
</style>
<script>
import { ref, computed, watch, inject, onMounted } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { ColorPicker } from 'vue-color-kit';
import { createToast } from 'mosha-vue-toastify';
import tzlookup from 'tz-lookup';
import { useI18n } from 'vue-i18n';
import moment from 'moment-timezone';
import countryList from '../../../assets/countries.json';
import TemplatePreview from '../../components/templates/TemplatePreview.vue';
import TemplateRolePreview from '../../components/templates/TemplateRolePreview.vue';
import SignatureHandler from '../../components/SignatureHandler.vue';
import ToggleButton from '../../components/ToggleButton.vue';
import BasicSmModal from '../../components/BasicSmModal.vue';
import ImageHandler from '../../components/ImageHandler.vue';
import TourGuide from '../../components/TourGuide.vue';

export default {
  props: {
    type: String,
    templateData: Object,
    noCloseOption: Boolean,
  },
  setup(props, context) {
    const store = useStore();
    const utils = inject('utils');
    const api = inject('api');
    const cookies = inject('cookies');
    const { t } = useI18n();

    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const currentAddress = computed(() => store.getters.getCurrentAddress);
    const pointAuthorized = computed(() => {
      return (
        process.env.VUE_APP_POINTS_AUTHORIZED_ISSUER_DIDS &&
        process.env.VUE_APP_POINTS_AUTHORIZED_ISSUER_DIDS.includes(
          currentIssuerDid.value
        )
      );
    });

    // COMMON BASE INFORMATION
    const templateCategory = ref(
      ['Basic', 'Community', 'Participation', 'Membership', 'Role'].indexOf(
        props.type
      )
    );
    if (props.type == 'Ticket') templateCategory.value = 2;
    const templateLabel = ref(utils.templateTypeToLabel(props.type, useI18n()));
    const templateLang = ref('en');
    const templateName = ref(null);
    const templateDescription = ref(null);
    const templateCriteria = ref(null);

    const templateImageFile = ref(null);
    const templateImagePreview = ref(null);
    const templateImageMessage = ref(null);

    const templateId = ref(null);
    const templateLocation = ref(null);
    const templateLocationVirtual = ref(false);
    const templateTimestamped = ref(false);
    const templatePublic = ref(true);
    const templateQuantity = ref(null);
    const templatePoints = ref(null);
    const templateOptionName = ref(true);
    const templateOptionSeat = ref(true);
    const templateOptionSection = ref(true);
    const templateValidity = ref(null);
    const templateValidityChoice = ref(null);
    const templateColor = ref('#456462');
    const templateEmoji = ref(null);
    const showEmojiPicker = ref(false);
    const emojiStock = ref([]);
    const emojiStockIndex = ref(0);
    const showColorPicker = ref(false);
    const showValidityMenu = ref(false);
    const showValidityMenuDate = ref(false);
    const startDatePickerValue = ref(null);
    const endDatePickerValue = ref(null);
    const showValidityMenuDuration = ref(false);
    const durationMonthValue = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    const templateStartDate = ref(null);
    const templateStartDateFormatted = ref(null);
    const showStartDatePicker = ref(false);
    const templateEndDate = ref(null);
    const templateEndDateFormatted = ref(null);
    const showEndDatePicker = ref(false);

    const showEditPictureButton = ref(false);
    const showImageHandler = ref(false);

    const signatureHandlerRef = ref();
    const waitingIpfs = ref(false);
    const newTemplateHash = ref(null);
    const waitingSignature = ref(null);
    const waitingSignatureAck = ref(false);
    const waitingTransaction = ref(null);
    const creationSuccessful = ref(false);
    const creationSuccessfulCompleted = ref(false);
    const creationSuccessfulSkip = ref(false);
    const updateSuccessful = ref(false);
    const qrCodeLink = ref('');
    const tourGuideRef = ref();

    watch(waitingSignature, () => {
      if (waitingSignature.value) waitingSignatureAck.value = true;
    });

    const locationQuery = ref('');
    const locationCountry = ref(null);
    const citiesAutoComplete = ref(null);
    const countries = ref(countryList);
    const showCountryPicker = ref(false);
    const locationTimezoneOffset = ref('+00:00');

    const emailList = ref([]);
    const emailListScrollDiv = ref(null);
    const emailInput = ref(null);
    const sendingInProgress = ref(false);
    const sendingSuccessful = ref(false);

    onMounted(() => {
      const tourGuideCreate = cookies.get('tourGuideCreate');
      if (!tourGuideCreate) {
        startTour();
      }
    });

    function startTour() {
      tourGuideRef.value.start([
        {
          content: t('tourGuide.createTour.imageTxt'),
          title: t('tourGuide.createTour.imageTitle'),
          id: 'tg-createTemplate-image',
        },
        {
          content: t('tourGuide.createTour.titleTxt'),
          title: t('tourGuide.createTour.titleTitle'),
          id: 'tg-createTemplate-title',
        },
        {
          content: t('tourGuide.createTour.descriptionTxt'),
          title: t('tourGuide.createTour.descriptionTitle'),
          id: 'tg-createTemplate-description',
        },
        {
          content: t('tourGuide.createTour.criteriaTxt'),
          title: t('tourGuide.createTour.criteriaTitle'),
          id: 'tg-createTemplate-criteria',
        },
        {
          content: t('tourGuide.createTour.validityTxt'),
          title: t('tourGuide.createTour.validityTitle'),
          id: 'tg-createTemplate-validity',
        },

        {
          content: t('tourGuide.createTour.timestampTxt'),
          title: t('tourGuide.createTour.timestampTitle'),
          id: 'tg-createTemplate-timestamp',
        },
        {
          content: t('tourGuide.createTour.previewTxt'),
          title: t('tourGuide.createTour.previewTitle'),
          id: 'tg-createTemplate-preview',
        },
        {
          content: t('tourGuide.createTour.colorTxt'),
          title: t('tourGuide.createTour.colorTitle'),
          id: 'tg-createTemplate-color',
        },
        {
          content: t('tourGuide.createTour.emojiTxt'),
          title: t('tourGuide.createTour.emojiTitle'),
          id: 'tg-createTemplate-emoji',
        },
        {
          content: t('tourGuide.createTour.startDateTxt'),
          title: t('tourGuide.createTour.startDateTitle'),
          id: 'tg-createTemplate-startDate',
        },
        {
          content: t('tourGuide.createTour.endDateTxt'),
          title: t('tourGuide.createTour.endDateTitle'),
          id: 'tg-createTemplate-endDate',
        },
        {
          content: t('tourGuide.createTour.virtualTxt'),
          title: t('tourGuide.createTour.virtualTitle'),
          id: 'tg-createTemplate-virtual',
        },
        {
          content: t('tourGuide.createTour.locationTxt'),
          title: t('tourGuide.createTour.locationTitle'),
          id: 'tg-createTemplate-location',
        },
        {
          content: t('tourGuide.createTour.quantityTxt'),
          title: t('tourGuide.createTour.quantityTitle'),
          id: 'tg-createTemplate-quantity',
        },
        {
          content: t('tourGuide.createTour.ticketOptionsTxt'),
          title: t('tourGuide.createTour.ticketOptionsTitle'),
          id: 'tg-createTemplate-ticketOptions',
        },
        {
          content: t('tourGuide.createTour.publicTxt'),
          title: t('tourGuide.createTour.publicTitle'),
          id: 'tg-createTemplate-public',
        },
      ]);
    }

    function finishTour() {
      cookies.set('tourGuideCreate', 1, -1);
    }

    watch(
      [
        templateLocation,
        templateEndDate,
        templateStartDate,
        templateLocationVirtual,
      ],
      () => {
        if (templateLocation.value) {
          const timezone = tzlookup(
            templateLocation.value.latitude,
            templateLocation.value.longitude
          );
          locationTimezoneOffset.value = moment()
            .utcOffset(moment.tz(timezone).utcOffset())
            .format('Z');
        }
        if (templateStartDate.value) {
          const templateStartLocalDateISOString = utils.getLocalDateISOString(
            new Date(templateStartDate.value).toISOString()
          );
          templateStartDateFormatted.value = templateLocationVirtual.value
            ? new Date(templateStartDate.value).toISOString()
            : templateStartLocalDateISOString.replace('Z', '') +
              locationTimezoneOffset.value;
        }
        if (templateEndDate.value) {
          const templateEndLocalDateISOString = utils.getLocalDateISOString(
            new Date(templateEndDate.value).toISOString()
          );
          templateEndDateFormatted.value = templateLocationVirtual.value
            ? new Date(templateEndDate.value).toISOString()
            : templateEndLocalDateISOString.replace('Z', '') +
              locationTimezoneOffset.value;
        }
      }
    );

    function formatDateWithLocation(date) {
      let locationTzOffset = '+00:00';
      if (templateLocation.value) {
        const timezone = tzlookup(
          templateLocation.value.latitude,
          templateLocation.value.longitude
        );
        locationTzOffset = moment()
          .utcOffset(moment.tz(timezone).utcOffset())
          .format('Z');
      }

      const dateISOString = utils.getLocalDateISOString(
        new Date(date).toISOString()
      );
      return (
        templateLocationVirtual.value
          ? new Date(date).toISOString()
          : dateISOString.replace('Z', '') + locationTzOffset
      ).replace(/T(\d{2}:\d{2}).*/, ' $1');
    }

    // If template update, fill fields with values
    if (props.templateData) {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Update',
          'Init',
        ]);

      templateId.value = props.templateData.data.id;
      templateDescription.value = props.templateData.data.description;

      utils
        .getBase64FromImageUrl(props.templateData.data.image)
        .then((data) => {
          templateImagePreview.value = data;
          templateImageFile.value = utils.base64ToFile(data);
          for (var i = 0; i < emojiStock.value.length; i++) {
            const emojiFound = emojiStock.value[i].find(
              (emoji) => emoji.path == data
            );
            if (emojiFound) templateEmoji.value = emojiFound;
          }
        });

      if (
        ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].includes(
          props.type
        )
      ) {
        templateName.value = props.templateData.data.name;
        templateCriteria.value = props.templateData.data.criteria;
      }
      if (['Basic', 'Role'].includes(props.type)) {
        if (props.templateData.data.expirationDate) {
          templateValidityChoice.value = 'expirationDate';
          templateValidity.value = props.templateData.data.expirationDate;
        } else if (props.templateData.data.expirationDuration) {
          templateValidityChoice.value = 'expirationDuration';
          templateValidity.value =
            props.templateData.data.expirationDuration / (30 * 24 * 60 * 60);
        } else {
          templateValidityChoice.value = 'infinite';
          templateValidity.value = t('templateCreation.infiniteTxt');
        }
      }
      if (['Basic'].includes(props.type)) {
        templateTimestamped.value = props.templateData.data.timestamped;
      }
      if (['Role'].includes(props.type)) {
        templateColor.value = props.templateData.data.hexColorRef;
      }
      if (['Participation', 'Ticket'].includes(props.type)) {
        if (props.templateData.data.eventDetails.location.length > 0) {
          getCityFromDetails(
            props.templateData.data.eventDetails.location.split(', ')[1],
            props.templateData.data.eventDetails.location.split(', ')[0]
          ).then((city) => {
            templateLocation.value = city;
            locationCountry.value = countries.value.find(
              (country) => country.iso2 == city.country_code
            );
            templateLocationVirtual.value = false;
          });
        } else {
          templateLocationVirtual.value = true;
        }

        templateStartDate.value = startDatePickerValue.value = new Date(
          props.templateData.data.eventDetails.startDate.split('.')[0]
        ).toISOString();

        templateEndDate.value = endDatePickerValue.value = new Date(
          props.templateData.data.eventDetails.endDate.split('.')[0]
        ).toISOString();
      }
      if (['Participation'].includes(props.type)) {
        templateQuantity.value = props.templateData.data.eventDetails.limit;
      }
      if (
        ['Basic', 'Participation', 'Ticket', 'Role'].includes(props.type) &&
        props.templateData.data.points
      ) {
        templatePoints.value = props.templateData.data.points;
      }
    } else {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Create',
          'Init',
        ]);
    }

    // Handle emojis for role template
    if (props.type == 'Role') {
      // can't put variable for path inside context method
      const emojiContexts = [
        require.context(`/assets/emojis/00_smileys_and_people`, false),
        require.context(`/assets/emojis/01_animals_and_nature`, false),
        require.context(`/assets/emojis/02_food_and_drink`, false),
        require.context(`/assets/emojis/03_activity`, false),
        require.context(`/assets/emojis/04_travel_and_places`, false),
        require.context(`/assets/emojis/05_objects`, false),
        require.context(`/assets/emojis/06_symbols`, false),
        require.context(`/assets/emojis/07_flags`, false),
      ];

      emojiStock.value = emojiContexts.map((emojiContext) => {
        const emojis = emojiContext.keys().reduce((array, path) => {
          return array.concat({
            path: emojiContext(path),
            name: path.substring(path.lastIndexOf('/') + 1),
          });
        }, []);
        return emojis;
      });

      watch(templateEmoji, () => {
        if (!templateEmoji.value) return;
        templateImageFile.value = utils.base64ToFile(
          templateEmoji.value.path,
          templateEmoji.value.name
        );
      });

      if (!props.templateData) templateEmoji.value = emojiStock.value[0][0];
    }

    const templateValidityFormatted = computed(() => {
      if (!templateValidity.value) return null;

      switch (templateValidityChoice.value) {
        case 'infinite':
          return t('templateCreation.infiniteTxt');
        case 'expirationDate':
          return (
            t('templateCreation.expiresOnTxt') +
            ' ' +
            utils.formatSimpleDate(templateValidity.value)
          );
        case 'expirationDuration':
          return (
            t('templateCreation.validDurationTxt') +
            ' ' +
            templateValidity.value +
            ' ' +
            (templateValidity.value > 1
              ? t('templateCreation.monthsTxt')
              : t('templateCreation.monthTxt'))
          );
      }

      return utils.formatSimpleDate(templateValidity.value);
    });

    onMounted(() => {
      const divs = document.querySelectorAll('.color-type');

      divs.forEach((div) => {
        const span = div.querySelector('.name');
        if (span && span.textContent === 'RGBA') {
          div.style.display = 'none';
        }
      });
    });

    watch([locationCountry, locationQuery], async () => {
      if (
        !locationCountry.value ||
        !locationQuery.value ||
        locationQuery.value.length < 3
      ) {
        citiesAutoComplete.value = null;
        return;
      }

      const cities = await getCitiesFromQuery(
        locationCountry.value.iso2,
        locationQuery.value
      );
      citiesAutoComplete.value = cities;
    });

    function showToastMessage(text) {
      createToast(text, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    }

    function checkInput() {
      if (
        ['Basic', 'Community', 'Participation', 'Ticket', 'Membership'].indexOf(
          props.type
        ) != -1
      ) {
        if (!templateImageFile.value)
          throw t('templateCreation.errors.pictureMissing');
      }
      if (
        ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].indexOf(
          props.type
        ) != -1
      ) {
        if (!templateName.value)
          throw t('templateCreation.errors.titleMissing');
        if (templateName.value.length > 100)
          throw t('templateCreation.errors.titleTooLong');
      }
      if (
        [
          'Basic',
          'Community',
          'Participation',
          'Ticket',
          'Membership',
          'Role',
        ].indexOf(props.type) != -1
      ) {
        if (!templateDescription.value)
          throw t('templateCreation.errors.descriptionMissing');
        if (templateDescription.value.length > 2000)
          throw t('templateCreation.errors.descriptionTooLong');
      }
      if (
        ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].indexOf(
          props.type
        ) != -1
      ) {
        if (!templateCriteria.value)
          throw t('templateCreation.errors.criteriaMissing');
        if (templateCriteria.value.length > 2000)
          throw t('templateCreation.errors.criteriaTooLong');
      }
      if (['Basic', 'Role'].indexOf(props.type) != -1) {
        if (!templateValidity.value)
          throw t('templateCreation.errors.validityMissing');
      }
      if (['Participation', 'Ticket'].indexOf(props.type) != -1) {
        if (!templateStartDate.value)
          throw t('templateCreation.errors.startDateMissing');
        if (!templateEndDate.value)
          throw t('templateCreation.errors.endDateMissing');
        if (!templateLocationVirtual.value && !templateLocation.value)
          throw t('templateCreation.errors.locationMissing');
        if (
          templateQuantity.value &&
          Number(templateQuantity.value) > 10000000000
        )
          throw t('templateCreation.errors.quantityNotValid');
      }
      if (
        ['Basic', 'Participation', 'Ticket', 'Role'].indexOf(props.type) != -1
      ) {
        if (
          templatePoints.value &&
          (!(
            !isNaN(parseFloat(templatePoints.value)) &&
            isFinite(templatePoints.value)
          ) ||
            Math.abs(Number(templatePoints.value) > 10000000000))
        )
          throw t('templateCreation.errors.pointsNotValid');
      }

      return true;
    }

    function checkEmailInput() {
      if (!emailList.value.length) throw t('signup.errors.emailMissing');
      return true;
    }

    async function getCitiesFromQuery(countryCode, query) {
      return (await api.getCitiesFromQuery(countryCode, query)).data;
    }

    async function getCityFromDetails(country, name) {
      return (await api.getCityFromDetails(country, name)).data;
    }

    async function addIssuerTemplate() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Create',
          'Confirm',
        ]);

      try {
        checkInput();
      } catch (err) {
        showToastMessage(err);
        return;
      }

      const imageCid = await utils.ipfs.calculateCid(
        await utils.ipfs.fileToUint8Array(templateImageFile.value)
      );

      let template = null;
      if (props.type == 'Basic') {
        const expirationDate =
          templateValidityChoice.value == 'expirationDate'
            ? templateValidity.value
            : null;
        const expirationDuration =
          templateValidityChoice.value == 'expirationDuration'
            ? templateValidity.value * 30 * 24 * 60 * 60
            : null;

        template = utils.badge.createBasicTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          uuidv4(),
          templateName.value,
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value,
          templateCriteria.value,
          templateTimestamped.value,
          expirationDate,
          expirationDuration,
          !templatePoints.value || templatePoints.value == ''
            ? null
            : templatePoints.value
        );
      } else if (props.type == 'Community') {
        template = utils.badge.createCommunityTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          uuidv4(),
          templateName.value,
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value,
          templateCriteria.value
        );
      } else if (props.type == 'Membership') {
        template = utils.badge.createMembershipTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          uuidv4(),
          'Membership card',
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value
        );
      } else if (props.type == 'Participation') {
        template = utils.badge.createParticipationTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          uuidv4(),
          templateName.value,
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value,
          templateCriteria.value,
          templateLocationVirtual.value
            ? ''
            : `${templateLocation.value.name}, ${templateLocation.value.country_name}`,
          templateStartDateFormatted.value,
          templateEndDateFormatted.value,
          !templateQuantity.value || templateQuantity.value == ''
            ? null
            : templateQuantity.value,
          !templatePoints.value || templatePoints.value == ''
            ? null
            : templatePoints.value
        );
      } else if (props.type == 'Ticket') {
        template = utils.badge.createTicketTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          uuidv4(),
          templateName.value,
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value,
          templateCriteria.value,
          templateLocationVirtual.value
            ? ''
            : `${templateLocation.value.name}, ${templateLocation.value.country_name}`,
          templateStartDateFormatted.value,
          templateEndDateFormatted.value,
          !templateQuantity.value || templateQuantity.value == ''
            ? null
            : templateQuantity.value,
          templateOptionName.value,
          templateOptionSeat.value,
          templateOptionSection.value,
          !templatePoints.value || templatePoints.value == ''
            ? null
            : templatePoints.value
        );
      } else if (props.type == 'Role') {
        const expirationDate =
          templateValidityChoice.value == 'expirationDate'
            ? templateValidity.value
            : null;
        const expirationDuration =
          templateValidityChoice.value == 'expirationDuration'
            ? templateValidity.value * 30 * 24 * 60 * 60
            : null;

        template = utils.badge.createRoleTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          uuidv4(),
          templateName.value,
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value,
          templateCriteria.value,
          expirationDate,
          expirationDuration,
          templateColor.value,
          !templatePoints.value || templatePoints.value == ''
            ? null
            : templatePoints.value
        );
      } else {
        throw 'Bad template type';
      }

      const templateCid = await utils.ipfs.calculateCid(
        Buffer.from(JSON.stringify(template))
      );
      const templateHash = utils.ipfs.cidToHash(templateCid);
      newTemplateHash.value = templateHash.replace('0x', '');

      // if not membership and draft selected, create draft
      if (props.type != 'Membership' && !templatePublic.value) {
        api
          .uploadTemplate(
            JSON.stringify({
              template,
            }),
            templateImageFile.value,
            props.templateData ? props.templateData.templateHash : null
          )
          .then(() => {
            window._paq.push([
              'trackEvent',
              `Template ${props.type}`,
              'Create draft',
              'Success',
            ]);
            context.emit('refresh');
            context.emit('close', templateHash);
          });
        return;
      }

      // if membership or draft unselected, create card on contract
      waitingSignature.value = 'signatureAdd';

      const name = props.type == 'Membership' ? '' : templateName.value;

      const methodName = 'addIssuerTemplate';
      const methodArgs = [
        {
          type: 'bytes32[]',
          value: [templateHash],
        },
        {
          type: 'bytes32[]',
          value: [
            '0x' +
              (
                '0000000000000000000000000000000000000000000000000000000000000000' +
                utils.asciiToHex(name).replace('0x', '')
              ).substr(-64),
          ],
        },
        {
          type: 'uint8',
          value: templateCategory.value,
        },
        {
          type: 'address',
          value: currentAddress.value,
        },
      ];

      const message = await utils.broadcast.broadcastEncode(
        currentAddress.value,
        methodName,
        ...methodArgs
      );

      let broadcastSignature = null;
      let badgeWithoutProof;
      let delegationSignature = null;

      let delegationExpirationDate =
        new Date(new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 19) + 'Z';
      if (props.type == 'Participation' || props.type == 'Ticket') {
        delegationExpirationDate =
          new Date(template.eventDetails.endDate).toISOString().slice(0, 19) +
          'Z';
      }

      badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        template.name.replace(/\0/g, ''),
        templateHash.replace('0x', ''),
        delegationExpirationDate
      );
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signAdd' + props.type,
          [
            {
              type: 'personal_sign',
              message,
            },
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }

      [broadcastSignature, delegationSignature] = signatures;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        delegationSignature
      );

      // Verify signatures
      const messageHash = utils.hashWithKeccak(message);
      const recoveredAddress = utils.sign.recoverPersonalSign(
        broadcastSignature,
        '0x' + messageHash
      );
      if (recoveredAddress != currentAddress.value) {
        throw new Error('Signature invalid for broadcast');
      }
      const badgeRecoveredAddress = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );
      if (badgeRecoveredAddress != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      api
        .uploadTemplate(
          JSON.stringify({
            template,
          }),
          templateImageFile.value,
          props.templateData ? props.templateData.templateHash : null
        )
        .then(() => {
          api
            .queueOperation('createTemplate', {
              did: currentIssuerDid.value,
              address: currentAddress.value,
              methodName,
              methodArgs,
              signature: broadcastSignature,
            })
            .then((res) => {
              if (props.type != 'Membership') {
                context.emit('refresh');
                context.emit('close', templateHash);
                return;
              }

              // launch completion watcher
              const operationId = res.data.callbackId;

              waitingSignature.value = null;
              creationSuccessful.value = true;

              const interval = setInterval(async () => {
                const operation = (await api.getOperation(operationId)).data;
                if (operation.state == 'success') {
                  console.log('operation successful !');
                  clearInterval(interval);
                  api
                    .createSession({
                      verifiableCredentials: [],
                      delegationBadge,
                      ...(template.eventDetails && {
                        startDate: template.eventDetails.startDate,
                      }),
                      ...(template.eventDetails &&
                        template.eventDetails.limit != -1 && {
                          didLimit: template.eventDetails.limit,
                        }),
                    })
                    .then((res) => {
                      window._paq.push([
                        'trackEvent',
                        `Template ${props.type}`,
                        'Create',
                        'Success',
                      ]);
                      qrCodeLink.value = res.data.links[0];

                      const code =
                        qrCodeLink.value.split('/')[
                          qrCodeLink.value.split('/').length - 1
                        ];
                      const codeHex =
                        '0x' +
                        (
                          '0000000000000000000000000000000000000000000000000000000000000000' +
                          utils.asciiToHex(code).replace('0x', '')
                        ).substr(-64);

                      api.queueOperation('addMemberCard', {
                        did: currentIssuerDid.value,
                        address: currentAddress.value,
                        linkHash: codeHex,
                      });

                      creationSuccessfulCompleted.value = true;
                      context.emit('refresh');
                    });
                }
              }, 1000);
            })
            .catch((error) => {
              console.error('Error while creating public membercard :', error);
            });
        });
    }

    async function updateIssuerTemplate() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Update',
          'Confirm',
        ]);

      try {
        checkInput();
      } catch (err) {
        showToastMessage(err);
        return;
      }

      const imageCid = await utils.ipfs.calculateCid(
        await utils.ipfs.fileToUint8Array(templateImageFile.value)
      );

      let template = null;
      if (props.type == 'Basic') {
        const expirationDate =
          templateValidityChoice.value == 'expirationDate'
            ? templateValidity.value
            : null;
        const expirationDuration =
          templateValidityChoice.value == 'expirationDuration'
            ? templateValidity.value * 30 * 24 * 60 * 60
            : null;

        template = utils.badge.createBasicTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          templateId.value ? templateId.value : uuidv4(),
          templateName.value,
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value,
          templateCriteria.value,
          templateTimestamped.value,
          expirationDate,
          expirationDuration,
          !templatePoints.value || templatePoints.value == ''
            ? null
            : templatePoints.value
        );
      } else if (props.type == 'Community') {
        template = utils.badge.createCommunityTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          templateId.value ? templateId.value : uuidv4(),
          templateName.value,
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value,
          templateCriteria.value
        );
      } else if (props.type == 'Membership') {
        template = utils.badge.createMembershipTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          templateId.value ? templateId.value : uuidv4(),
          'Membership card',
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value
        );
      } else if (props.type == 'Participation') {
        template = utils.badge.createParticipationTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          templateId.value ? templateId.value : uuidv4(),
          templateName.value,
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value,
          templateCriteria.value,
          templateLocationVirtual.value
            ? ''
            : `${templateLocation.value.name}, ${templateLocation.value.country_name}`,
          templateStartDateFormatted.value,
          templateEndDateFormatted.value,
          !templateQuantity.value || templateQuantity.value == ''
            ? null
            : templateQuantity.value,
          !templatePoints.value || templatePoints.value == ''
            ? null
            : templatePoints.value
        );
      } else if (props.type == 'Ticket') {
        template = utils.badge.createTicketTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          templateId.value ? templateId.value : uuidv4(),
          templateName.value,
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value,
          templateCriteria.value,
          templateLocationVirtual.value
            ? ''
            : `${templateLocation.value.name}, ${templateLocation.value.country_name}`,
          templateStartDateFormatted.value,
          templateEndDateFormatted.value,
          !templateQuantity.value || templateQuantity.value == ''
            ? null
            : templateQuantity.value,
          templateOptionName.value,
          templateOptionSeat.value,
          templateOptionSection.value,
          !templatePoints.value || templatePoints.value == ''
            ? null
            : templatePoints.value
        );
      } else if (props.type == 'Role') {
        const expirationDate =
          templateValidityChoice.value == 'expirationDate'
            ? templateValidity.value
            : null;
        const expirationDuration =
          templateValidityChoice.value == 'expirationDuration'
            ? templateValidity.value * 30 * 24 * 60 * 60
            : null;

        template = utils.badge.createRoleTemplate(
          currentIssuerDid.value,
          props.type,
          templateLang.value,
          templateId.value ? templateId.value : uuidv4(),
          templateName.value,
          utils.ipfs.getUrlFromCID(imageCid),
          templateDescription.value,
          templateCriteria.value,
          expirationDate,
          expirationDuration,
          templateColor.value,
          !templatePoints.value || templatePoints.value == ''
            ? null
            : templatePoints.value
        );
      } else {
        throw 'Bad template type';
      }

      const templateCid = await utils.ipfs.calculateCid(
        Buffer.from(JSON.stringify(template))
      );
      const templateHash = utils.ipfs.cidToHash(templateCid);
      newTemplateHash.value = templateHash.replace('0x', '');

      // if draft, only update DB
      if (props.templateData.index == -1) {
        api
          .uploadTemplate(
            JSON.stringify({
              template,
            }),
            templateImageFile.value,
            props.templateData.templateHash
          )
          .then(() => {
            if (window._paq)
              window._paq.push([
                'trackEvent',
                `Template ${props.type}`,
                'Update draft',
                'Success',
              ]);
            context.emit('refresh');
            context.emit('close', templateHash);
          });
        return;
      }

      // if public template, update on contract and update delegation badge

      waitingSignature.value = 'signatureEdit';

      const name = props.type == 'Membership' ? '' : templateName.value;

      const methodName = 'updateIssuerTemplate';
      const methodArgs = [
        {
          type: 'bytes32',
          value: templateHash,
        },
        {
          type: 'bytes32',
          value:
            '0x' +
            (
              '0000000000000000000000000000000000000000000000000000000000000000' +
              utils.asciiToHex(name).replace('0x', '')
            ).substr(-64),
        },
        {
          type: 'uint256',
          value: Number(props.templateData.index),
        },
        {
          type: 'uint8',
          value: templateCategory.value,
        },
        {
          type: 'address',
          value: currentAddress.value,
        },
      ];

      const message = await utils.broadcast.broadcastEncode(
        currentAddress.value,
        methodName,
        ...methodArgs
      );

      let broadcastSignature = null;
      let badgeWithoutProof;
      let delegationSignature = null;

      let delegationExpirationDate =
        new Date(new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 19) + 'Z';
      if (props.type == 'Participation' || props.type == 'Ticket') {
        delegationExpirationDate =
          new Date(template.eventDetails.endDate).toISOString().slice(0, 19) +
          'Z';
      }

      badgeWithoutProof = utils.badge.createDelegationBadge(
        uuidv4(),
        currentIssuerDid.value + '#SERV_1',
        process.env.VUE_APP_BADGE_BOT_ISSUER_DID,
        template.name.replace(/\0/g, ''),
        templateHash,
        delegationExpirationDate
      );
      const typedData = await utils.sign.getVCTypedDataV4(badgeWithoutProof);

      let signatures = null;
      try {
        signatures = await signatureHandlerRef.value.sign(
          'MultiSign',
          'signEdit' + props.type,
          [
            {
              type: 'personal_sign',
              message,
            },
            {
              type: 'eth_signTypedData',
              message: typedData,
            },
          ]
        );
      } catch (e) {
        console.log(e);
        waitingSignature.value = null;
        return;
      }

      [broadcastSignature, delegationSignature] = signatures;

      const delegationBadge = await utils.sign.addProofToVerifiableCredential(
        badgeWithoutProof,
        currentIssuerDid.value,
        delegationSignature
      );

      // Verify signatures
      const messageHash = utils.hashWithKeccak(message);
      const recoveredAddress = utils.sign.recoverPersonalSign(
        broadcastSignature,
        '0x' + messageHash
      );
      if (recoveredAddress != currentAddress.value) {
        throw new Error('Signature invalid for broadcast');
      }
      const badgeRecoveredAddress = await utils.sign.recoverVCTypedSignatureV4(
        delegationBadge
      );
      if (badgeRecoveredAddress != currentAddress.value) {
        throw new Error('Signature invalid for verifiable credential');
      }

      api
        .uploadTemplate(
          JSON.stringify({ template }),
          templateImageFile.value,
          props.templateData.templateHash
        )
        .then(() => {
          // upload template has to be finished before operation trigger, because hash in methodArgs operation should correspond to hash in db template
          Promise.all([
            api.queueOperation('updateTemplate', {
              did: currentIssuerDid.value,
              address: currentAddress.value,
              methodName,
              methodArgs,
              signature: broadcastSignature,
              oldTemplateHash: props.templateData.templateHash,
            }),
            api.updateSession({
              oldTemplateHash: props.templateData.templateHash,
              newTemplateHash: templateHash,
              delegationBadge: delegationBadge,
              ...(props.type == 'Participation' && {
                didLimit:
                  !templateQuantity.value || templateQuantity.value == ''
                    ? -1
                    : Number(templateQuantity.value),
              }),
              ...((props.type == 'Ticket' || props.type == 'Participation') && {
                startDate: templateStartDateFormatted.value,
              }),
            }),
          ])
            .then(() => {
              window._paq.push([
                'trackEvent',
                `Template ${props.type}`,
                'Update',
                'Success',
              ]);
              waitingSignature.value = null;
              context.emit('refresh');
              context.emit('close', templateHash);
            })
            .catch((error) => {
              console.error('Error while updating public template :', error);
            });
        });
    }

    async function sendOpenLink() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Send open link onboarding',
          'Confirm',
        ]);

      try {
        checkEmailInput();
      } catch (err) {
        showToastMessage(err);
        return;
      }

      creationSuccessful.value = false;

      sendingInProgress.value = true;
      api
        .sendOpenSessionByEmail({
          emails: emailList.value,
          templateHash: newTemplateHash.value,
        })
        .then(() => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.type}`,
              'Send open link onboarding',
              'Success',
            ]);
          sendingInProgress.value = false;
          sendingSuccessful.value = true;
        });
    }

    function deleteIssuerPictureInput() {
      templateImageFile.value = null;
      templateImageMessage.value = null;
      templateImagePreview.value = null;
    }

    function triggerCloseEvent(cancel) {
      if (cancel && props.templateData)
        context.emit('close', props.templateData.templateHash);
      else context.emit('close', newTemplateHash.value);
    }

    function treatEmailInput() {
      if (!emailInput.value) return;
      const potentialEmails = emailInput.value.split(/[\s,;]+/);
      const validEmails = potentialEmails.filter((email) =>
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)
      );

      emailList.value = [...new Set([...emailList.value, ...validEmails])]; // new Set() removes duplicate
      emailInput.value = null;
    }

    function removeFromEmailList(index) {
      emailList.value.splice(index, 1);
    }

    async function copyToClipboard() {
      await navigator.clipboard.writeText(qrCodeLink.value);
      createToast(t('other.clipboardCopyTxt'), {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#050931',
      });
    }

    return {
      currentIssuerInfo,
      triggerCloseEvent,
      templateLabel,
      templateName,
      templateDescription,
      templateLocation,
      templateLocationVirtual,
      templateCriteria,
      templateTimestamped,
      templatePublic,
      templateQuantity,
      templatePoints,
      templateOptionName,
      templateOptionSeat,
      templateOptionSection,
      templateValidity,
      templateValidityChoice,
      templateValidityFormatted,
      templateColor,
      templateEmoji,
      templateImageFile,
      templateImagePreview,
      templateImageMessage,
      deleteIssuerPictureInput,
      showEmojiPicker,
      emojiStock,
      emojiStockIndex,
      showColorPicker,
      showEditPictureButton,
      showValidityMenu,
      showValidityMenuDate,
      showValidityMenuDuration,
      durationMonthValue,
      startDatePickerValue,
      endDatePickerValue,
      showStartDatePicker,
      showEndDatePicker,
      templateStartDate,
      templateStartDateFormatted,
      templateEndDate,
      templateEndDateFormatted,
      waitingIpfs,
      waitingSignature,
      waitingSignatureAck,
      waitingTransaction,
      creationSuccessful,
      creationSuccessfulCompleted,
      creationSuccessfulSkip,
      updateSuccessful,
      qrCodeLink,
      addIssuerTemplate,
      updateIssuerTemplate,
      signatureHandlerRef,
      locationQuery,
      locationCountry,
      citiesAutoComplete,
      countries,
      showCountryPicker,
      showImageHandler,
      newTemplateHash,
      emailList,
      emailListScrollDiv,
      emailInput,
      treatEmailInput,
      removeFromEmailList,
      copyToClipboard,
      sendOpenLink,
      sendingSuccessful,
      sendingInProgress,
      startTour,
      tourGuideRef,
      finishTour,
      formatDateWithLocation,
      utils,
      pointAuthorized,
    };
  },
  components: {
    TemplatePreview,
    TemplateRolePreview,
    ToggleButton,
    SignatureHandler,
    BasicSmModal,
    ColorPicker,
    ImageHandler,
    TourGuide,
  },
};
</script>
